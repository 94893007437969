
import React, { useEffect, useState } from 'react';
import { getUserDetailsNested } from './helper/coreapicalls';

const DetailedUserView = ({ timesheet, onUpdate, onClosePopup }) => {

  const [empData, setEmpData] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      const result = await getUserDetailsNested(timesheet.timesheet_emp_id)
      setEmpData(result)
    }
    fetchData();
  }, [timesheet]);


  return (
    <div className="box popup " style={{ maxWidth: 900 }}>
      <button className='fa fa-window-close' onClick={onClosePopup}></button>
      <div className="popup-content">
        <div className='text-primary' style={{ fontWeight: 'bold' }}>
          Employee Details
        </div>
        <table className="table">
          <thead>
            <tr>
              <th scope="col" style={{fontSize:13}} >MSID</th>
              <th scope="col" style={{fontSize:13}} >Name</th>
              <th scope="col" style={{fontSize:13}} >Email</th>
              <th scope="col" style={{fontSize:13}} >Phone</th>
              <th scope="col" style={{fontSize:13}} >Country</th>
              <th scope="col" style={{fontSize:13}} >Branch</th>
              <th scope="col" style={{fontSize:13}} >Department</th>
              <th scope="col" style={{fontSize:13}} >Team</th>
              {/* <th scope="col">Activation Status</th> */}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{fontSize:14, alignContent:'center'}} >
                {empData ? empData.emp_msid : 'Loading...'}
              </th>
              <td style={{fontSize:14, alignContent:'center'}}>
                {empData ? empData.emp_name : 'Loading...'}
              </td>
              <td style={{fontSize:14, alignContent:'center'}}>
                {empData ? empData.emp_email : 'Loading...'}
              </td>
              <td style={{fontSize:14, alignContent:'center'}}>
                {empData ? empData.emp_phone : 'Loading...'}
              </td>
              <td style={{fontSize:14, alignContent:'center'}}>
                {empData ? empData.emp_country_id.country_name : 'Loading...'}
              </td>
              <td style={{fontSize:14, alignContent:'center'}}>
                {empData ? empData.emp_branch_id.branch_name : 'Loading...'}
              </td>
              <td style={{fontSize:14, alignContent:'center'}}>
                {empData ? empData.emp_dept_id.department_name : 'Loading...'}
              </td>
              <td style={{fontSize:14, alignContent:'center'}}>
                {empData ? empData.emp_team_id.team_name : 'Loading...'}
              </td>
            </tr>
          </tbody>

        </table>

        <div className='text-primary' style={{ fontWeight: 'bold' }}>
          Allowances
        </div>

        <div>{empData?.emp_quota[0] ? <table className='table'>
          <thead>
            <tr>
              <th scope="col">Holiday</th>
              <th scope="col">Anchorage</th>
              <th scope="col">OT/Hr</th>
              <th scope="col">Overseas</th>
              <th scope="col">DryDock</th>
              {/* <th scope="col">Activation Status</th> */}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="col">{empData?.emp_quota[0].emp_quota_holiday}</th>
              <th scope="col">{empData?.emp_quota[0].emp_quota_offshore}</th>
              <th scope="col">{empData?.emp_quota[0].emp_quota_otperhour}</th>
              <th scope="col">{empData?.emp_quota[0].emp_quota_overseas}</th>
              <th scope="col">{empData?.emp_quota[0].emp_quota_dubaidrydock}</th>

            </tr>
          </tbody>
        </table> : (
          <div className='bg-danger text-white ' style={{ maxWidth: 'auto' }}>          <span >
            No allowances are assigned for {empData?.emp_name || 'This Employee'}     !!
          </span></div>
        )}
        </div>



        <div className='text-primary' style={{ fontWeight: 'bold' }}>
          Work Details
        </div>

        <div>
          {empData?.emp_quota[0] ? (
            <table className='table'>
              <thead>
                <tr style={{fontSize : 13}}>
                  <th scope="col">Job No:</th>
                  <th scope="col">Location</th>
                  <th scope="col">Database</th>
                  <th scope="col">Vessel Name</th>
                  <th scope="col">Descriptions</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{fontSize : 13}}>

                  <th scope="col">
                    {timesheet.timesheet_work_id?.work_job_number || 'N/A'}
                  </th>

                  <th scope="col">
                    {timesheet.timesheet_work_id?.work_location || 'N/A'}
                  </th>
                  <th scope="col">
                    {timesheet.timesheet_work_id?.branch_name || 'N/A'}
                  </th>
                  <th scope="col">
                    {timesheet.timesheet_work_id?.work_vessel || 'N/A'}
                  </th>

                  <th scope="col">
                    {timesheet.timesheet_attendance_id?.attendance_remarks || 'N/A'}
                  </th>

                </tr>
              </tbody>
            </table>
          ) : (
            <div className='bg-danger text-white ' style={{ maxWidth: 'auto' }}>
              <span>
                No work data available {empData?.emp_name || 'This Employee'}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>

  );
};
export default DetailedUserView
