import Menu from "../core/Menu";
import Footer from "../core/Footer";
import React, { useEffect, useState,useRef  } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { createAttendance, getWorkDetails } from "../core/helper/coreapicalls";
import WorkDetails from "./WorkDetails";
import AttendanceForm from "./AttendanceForm";

const CreateAttendance = () => {
  const [workDetails, setWorkDetails] = useState({});
  const { workId } = useParams();
  const [showForm, setShowForm] = useState(false); // State variable to show/hide form
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const formRef = useRef(null);

  const loadingMessage = () => {
    return (
      loading && (
        <div className="overlay">
          <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="spinner-border text-danger" role="status"></div>
          </div>
        </div>
      )
    );
  };

  useEffect(() => {
    // Scroll to AttendanceForm when showForm becomes true
    if (showForm && formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [showForm]);

  useEffect(() => {
    const fetchWorkDetails = async () => {
      try {
        setLoading(true);
        const workDetails = await getWorkDetails(workId);
        setWorkDetails(workDetails);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching work details:", error);
      }
    };
    fetchWorkDetails();
  }, [workId]);

  const handleCreateAttendance = async (formData) => {
    try {
      const filteredData = Object.fromEntries(
        Object.entries(formData)
        .filter(([_, value]) => value !== null && value !== "")
     
      );

      const isConfirmed = window.confirm(
        `Please confirm the data before submitting:\n\n${getFormattedDataForAlert(
          filteredData
        )}\n\nDo you want to proceed?`
      );
      if (!isConfirmed) {
        return;
      }
      setLoading(true);
      await createAttendance(filteredData);
      setLoading(false);
      alert(`Success !!`);
      navigate("/user/dashboard/");
    } catch (error) {
      console.error("Error creating attendance:", error);
      alert("Failed to submit attendance. Please try again.");
      setLoading(false);
    }
  };

  const getFormattedDataForAlert = (data) => {
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(
        ([key, value]) =>
          key !== "attendance_work" &&
          key !== "attendance_emp" &&
          key !== "attendance_emp_team" &&
          key !== "attendance_do_upload_location"&&
          key !== "attendance_sr_upload_location"&&
          value !== false
      )
    );
  
    // Rename specific keys using template literals
    const renamedKeys = {
      attendance_is_anchorage: "Anchorage",
      attendance_is_dubaidrydock: "Dubai Dry Dock",
      attendance_is_overseas: "Overseas",
      attendance_is_not_possible: "Not Possible",
      attendance_is_completed: "Completed",
      attendance_is_do_available: "DO Available",
      attendance_is_sr_available: "SR Available",
      attendance_remarks: "Remarks",
      attendance_date: "Date",
      attendance_start_date : "Start Date",
      attendance_end_date : "End Date",
      attendance_do_numbers: "DO Number",
      attendance_sr_numbers: "SR Number",
    };
  
    const confirmationText = Object.entries(filteredData)
      .map(([key, value]) => {
        // Use template literal for direct replacement
        return `${renamedKeys[key] || key.replace(/^attendance_is_/, "")}: ${value}`;
      })
      .join("\n");
  
    return confirmationText;
  };
  

  return (
    <div className="d-flex flex-column min-vh-100">
      {loading && loadingMessage()}
      <Menu />
      <div className="flex-grow-1  ">
        <div style={{ paddingLeft: 15, paddingBottom: 5 }}>
          <h4>Work Details</h4>
        </div>
        <WorkDetails workDetails={workDetails} />
        <div
          style={{
            display: "flex",
            paddingLeft: "15px",
            paddingBottom: "1px",
            paddingRight: "15px",
            maxWidth: "250px",
            margin: "auto",
          }}
        >
          <button className="btn1" onClick={() => setShowForm(!showForm)}>
            {showForm ? "Create Attendance" : "Create Attendance"}
          </button>
        </div>
      </div>
      <br></br>
      <div ref={formRef}>
        {showForm && (
          <AttendanceForm
            workDetails={workDetails}
            handleCreateAttendance={handleCreateAttendance}
          />
        )}
      </div>
      <br></br>
      <Footer />
    </div>
  );
};
export default CreateAttendance;