import React, { useState } from 'react';
import CountryDropdown from '../user/CountryDropdown';
import DepartmentDropdown from '../user/DepartmentDropdown';
import BranchDropdown from '../user/BranchDropdown';

const EditWorkPopup = ({ work, onSave, onClose }) => {

  const [editedWork, setEditedWork] = useState(work || '');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Replace null values with an empty string
    const sanitizedValue = value !== null ? value : '';
    setEditedWork((prevData) => ({ ...prevData, [name]: sanitizedValue }));
  };

  const handleCountryChange = (selectedCountry) => {
    setEditedWork({
      ...editedWork,
      work_country_id: selectedCountry ? selectedCountry.country_id : '',
    });
  };

  const handleDepartmentChange = (selectedDepartment) => {
    setEditedWork({
      ...editedWork,
      work_dept_id: selectedDepartment ? selectedDepartment.department_id : '',
    });
  };

  const handleBranchChange = (selectedBranch) => {
    setEditedWork({
      ...editedWork,
      work_branch_id: selectedBranch ? selectedBranch.branch_id : '',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Pass the updated work data to the parent component
      await onSave(editedWork);
      onClose(); // Close the popup
    } catch (error) {
      console.error('Error saving changes:', error);
      // Handle error, if needed
    }
  };
  
  return (
    <div className="popup">
      <div className="popup-content">
        <span className="close" onClick={onClose}style={{ color: 'black' }}>
          &times;
        </span>
        <h2 style={{ color: 'black'}} >Edit Work Details</h2>
        <form onSubmit={handleSubmit}>
          <div className="row p-2 mb-1">
            <div className="col-xl-4 ">
              <label htmlFor="countryDropdown" style={{ color: 'black' }}>Country</label><br></br>
              <CountryDropdown onSelectCountry={handleCountryChange} defaultValue={editedWork.work_country_id} />
            </div>
            <div className="col-xl-4">
              <label htmlFor="departmentDropdown" style={{ color: 'black' }}>Department</label><br></br>
              <DepartmentDropdown onSelectDepartment={handleDepartmentChange} defaultValue={editedWork.work_dept_id} />
            </div>
            <div className="col-xl-4">
              <label htmlFor="branchDropdown" style={{ color: 'black' }}>Branch (Database)</label><br></br>
              <BranchDropdown onSelectBranch={handleBranchChange} defaultValue={editedWork.work_branch_id} />
            </div>
          </div>

          <div className='row'>
            <div className="col-xl-4 col-md-6 mb-1">
              <div className="card border-left-primary shadow h-100 py-2">
                <div className="card-body">
                  <label>
                    Vessel:<br></br>
                    <input
                      type="text"
                      name="work_vessel"
                      value={editedWork.work_vessel}
                      onChange={handleInputChange}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-6 mb-1">
              <div className="card border-left-primary shadow h-100 py-2">
                <div className="card-body">
                  <label>
                    IMO:<br></br>
                    <input
                      type="text"
                      name="work_vessel_imo"
                      value={editedWork.work_vessel_imo}
                      onChange={handleInputChange}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 mb-1">
              <div className="card border-left-primary shadow h-100 py-2">
                <div className="card-body">
                  <label>
                    Job Number:<br></br>
                    <input
                      type="text"
                      name="work_job_number"
                      value={editedWork.work_job_number}
                      onChange={handleInputChange}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className="col-xl-4 col-md-6 mb-1">
              <div className="card border-left-primary shadow h-100 py-2">
                <div className="card-body">
                  <label>
                    Job Description:<br></br>
                    <textarea
                      name="work_job_description"
                      value={editedWork.work_job_description}
                      onChange={handleInputChange}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-6 mb-1">
              <div className="card border-left-primary shadow h-100 py-2">
                <div className="card-body">
                  <label>
                    PIC:<br></br>
                    <input
                      type="text"
                      name="work_job_pic_name"
                      value={editedWork.work_job_pic_name}
                      onChange={handleInputChange}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-6 mb-1">
              <div className="card border-left-primary shadow h-100 py-2">
                <div className="card-body">
                  <label>
                    Location:<br></br>
                    <input
                      type="text"
                      name="work_location"
                      value={editedWork.work_location}
                      onChange={handleInputChange}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className="col-xl-4 col-md-6 mb-1">
              <div className="card border-left-primary shadow h-100 py-2">
                <div className="card-body">
                  <label>
                    Date:<br></br>
                    <input
                      type="date"
                      name="work_date"
                      value={editedWork.work_date}
                      onChange={handleInputChange}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-6 mb-1">
              <div className="card border-left-primary shadow h-100 py-2">
                <div className="card-body">
                  <label>
                    Type:<br></br>
                    <input
                      type="text"
                      name="work_type"
                      value={editedWork.work_type}
                      onChange={handleInputChange}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-6 mb-1">
              <div className="card border-left-primary shadow h-100 py-2">
                <div className="card-body">
                  <label>
                    Active:<br></br>
                    <input
                      type="checkbox"
                      name="work_active"
                      checked={editedWork.work_active}
                      onChange={() =>
                        setEditedWork((prevData) => ({
                          ...prevData,
                          work_active: !prevData.work_active,
                        }))
                      }
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>

          <button type="submit" className="btn btn-primary">Save Changes</button>
        </form>
      </div>
    </div>
  );
};
export default EditWorkPopup;