import React, { useState, useEffect } from "react";
import DetailedUserView from "./DetailedUserView";
import {
  generateReportApi,
  updateTimesheetProcessData,
} from "./helper/coreapicalls";
import Pagination from "./Pagination"; // Import the Pagination component
import TimesheetSearch from "./TimesheetSearch";
import { getAllTimesheetsByFilter } from "./helper/coreapicalls";

const TeamLeadControl = (onClosePopup) => {
  const [selectedTimesheet, setSelectedTimesheet] = useState(null);
  const [timesheetsData, setTimesheetsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  // const [processStatus, setProcessStatus] = useState(0)

  const loadingMessage = () => {
    return (
      loading && (
        <div className="overlay">
          <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="spinner-border text-danger" role="status"></div>
          </div>
        </div>
      )
    );
  };

  // Fetch data when currentPage changes
  useEffect(() => {
    fetchTimesheets(currentPage, filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleViewClick = (timesheet) => {
    setSelectedTimesheet(timesheet);
  };

  function decimalToTime(decimalHours) {
    // Split the decimal into hours and minutes
    var hours = Math.floor(decimalHours);
    var minutes = Math.round((decimalHours - hours) * 60);
    // Format the time
    var formattedTime = hours + ":" + (minutes < 10 ? "0" : "") + minutes;
    return formattedTime;
  }

  const handleProcessClick = async (timesheet) => {
    try {
      setLoading(true);
      const updatedTimesheet = { ...timesheet };
      updatedTimesheet.timesheet_final_process_status =
        !timesheet.timesheet_final_process_status;
      const jsonData = {
        timesheet_emp_id: timesheet.timesheet_emp_id,
        timesheet_final_process_status:
          updatedTimesheet.timesheet_final_process_status,
      };
      await updateTimesheetProcessData(timesheet.timesheet_id, jsonData);
      // Update the timesheet data in the state to reflect the changes
      setTimesheetsData((prevData) =>
        prevData.map((item) =>
          item.timesheet_id === timesheet.timesheet_id ? updatedTimesheet : item
        )
      );
      // Update processStatus state to track the state change
      // setProcessStatus(prevStatus => prevStatus === 0 ? 1 : 0);
      setLoading(false);
    } catch (error) {
      console.error("Error updating timesheet:", error);
      setLoading(false);
    }
  };

  const handleSaveEdit = (editedTimesheet) => {
    setTimesheetsData((prevData) =>
      prevData.map((timesheet) =>
        timesheet.timesheet_id === editedTimesheet.timesheet_id
          ? editedTimesheet
          : timesheet
      )
    );
    setSelectedTimesheet(null);
  };

  const handleCloseEdit = () => {
    setSelectedTimesheet(0);
  };

  // const handleNextPage = () => {
  //   setCurrentPage(currentPage + 1);
  // };

  // const handlePrevPage = () => {
  //   setCurrentPage(currentPage - 1);
  // };

  const handleSearch = async (searchFilters) => {
    // console.log("filers:", searchFilters)
    setFilters(searchFilters);
    setCurrentPage(1);
    await fetchTimesheets(1, searchFilters);
  };

  const handleReportGen = async (searchFilters) => {
    console.log("jenvjdfvndfvndfn vknd fv", searchFilters)
    setFilters(searchFilters);
    setCurrentPage(1);
    await generateReport(1, searchFilters);
  };

  const handlePageChange = async (newPage) => {
    setCurrentPage(newPage);
    await fetchTimesheets(newPage, filters);
  };

  const fetchTimesheets = async (page = 1, searchFilters = filters, approved = true) => {
    try {
      setLoading(true);
  
      const result = await getAllTimesheetsByFilter(
        searchFilters.teamlead === true ? searchFilters.teamlead : null,
        searchFilters.manager === true ? searchFilters.manager : null,
        searchFilters.sr === true ? searchFilters.sr : null,
        searchFilters.teamId && searchFilters.teamId.team_id
          ? searchFilters.teamId.team_id
          : null,
        searchFilters.branchId && searchFilters.branchId.branch_id
          ? searchFilters.branchId.branch_id
          : null,
        page,
        searchFilters.msid,
        searchFilters.eng_name,
        searchFilters.vessel_name,
        searchFilters.startdate,
        searchFilters.enddate,
        searchFilters.Job_No,
        searchFilters.OT === true ? searchFilters.OT : null,
        approved,

      );
      // console.log(result.timesheetsData)
      setTimesheetsData(result.timesheetsData);
      setTotalPages(Math.ceil(result.count / 100));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching timesheet data:", error);
      setLoading(false);
    }
  };

  const generateReport = async (page = 1, searchFilters = filters,approved=true) => {
    try {
      console.log("filterssssssssss:", searchFilters.branchId)
      setLoading(true);
      console.log("Generateee  Filters");
      const result = await generateReportApi(
        searchFilters.teamlead === true ? searchFilters.teamlead : null,
        searchFilters.manager === true ? searchFilters.manager : null,
        searchFilters.sr === true ? searchFilters.sr : null,
        searchFilters.teamId && searchFilters.teamId.team_id
          ? searchFilters.teamId.team_id
          : null,
        searchFilters.branchId && searchFilters.branchId.branch_id
          ? searchFilters.branchId.branch_id
          : null,
        page,
        searchFilters.msid,
        searchFilters.eng_name,
        searchFilters.vessel_name,
        searchFilters.startdate,
        searchFilters.enddate,
        searchFilters.Job_No,
        searchFilters.OT === true ? searchFilters.OT : null,
        approved,
      );

      downloadFileFromResult(result);
      // setAllTimesheetsData(result.timesheetsData);
      // setTotalPages(Math.ceil(result.count / 100));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching timesheet data:", error);
      setLoading(false);
    }
  };

  const downloadFileFromResult = ({ blob, headers }) => {
    // Create a new Blob object using the response data (the Excel file)
    const fileBlob = new Blob([blob], { type: headers.get("content-type") });

    // Create a temporary anchor element to trigger the download
    const url = window.URL.createObjectURL(fileBlob);
    const link = document.createElement("a");
    link.href = url;

    // Get the filename from the Content-Disposition header if available
    const contentDisposition = headers.get("content-disposition");
    let fileName = "downloaded_file.xlsx"; // Default filename
    if (contentDisposition && contentDisposition.includes("filename=")) {
      fileName = contentDisposition
        .split("filename=")[1]
        .split(";")[0]
        .replace(/"/g, ""); // Extract the filename
    }

    // Set the file name for the downloaded file
    link.setAttribute("download", fileName);

    // Append the link to the document body and click it to start the download
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const normalUserfilterConfig = [
    { type: "text", name: "msid", label: "MSID" },
    { type: "text", name: "eng_name", label: "Eng_Name" },
    { type: "text", name: "vessel_name", label: "Vessel_Name" },
    { type: "text", name: "Job_No", label: "Job_No" },
    { type: "date", name: "startdate", label: "Start" },
    { type: "date", name: "enddate", label: "End" },
    { type: "checkbox", name: "OT", label: "Overtime" },
    { type: "select", name: "Team", label: "Team" },
    { type: "select", name: "Branch", label: "Branch" },
  ];

  return (
    <div className="row">
      {loading && loadingMessage()}
      <div className="pb-2  ">
        <div className="mt-2" >
          <div style={{ justifyContent: "start",  alignItems:"start", alignContent:"start"}} >
            <div >            
              <TimesheetSearch
              initialConfig={normalUserfilterConfig}
              onSearch={handleSearch}
              onReportGen={handleReportGen}
            /></div>
          </div>
        </div>
      </div>
      <div
        className="table-container card border-left-primary shadow m-2"
        style={{ maxHeight: "500px", overflowY: "auto", overflowX: "scroll" }}
      >
        <table className="table table-striped">
          <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
            <tr
              style={{
                maxHeight: 30,
                fontSize: 12,
                alignContent: "center",
                textAlign: "center",
              }}
            >
              <th style={{ maxWidth:35}}>View</th>
              <th style={{ maxWidth: 85 }}>
                Date
                <br />
                y-m-d
              </th>
              <th style={{ maxWidth: 85 }}>
                Eng
                <br />
                Name
              </th>
              <th style={{ maxWidth: 65 }}>
                Start
                <br />
                Time
              </th>
              <th style={{ maxWidth: 65 }}>
                Sign
                <br />
                In
              </th>
              <th style={{ maxWidth: 65 }}>
                Sign
                <br />
                Out
              </th>
              <th style={{ maxWidth: 65 }}>Return</th>
              <th style={{ maxWidth: 58 }}>
                Normal
                <br />
                Hrs
              </th>
              <th style={{ maxWidth: 45 }}>
                Total
                <br />
                Hrs
              </th>
              <th style={{ maxWidth: 52 }}>
                OT
                <br />
                Hrs
              </th>
              <th style={{ maxWidth: 45 }}>
                Exp.
                <br />
                OT
              </th>
              <th style={{ maxWidth: 59 }}>
                OT
                <br />
                Amt
              </th>
              <th style={{ maxWidth: 72 }}>Alw.Amt</th>
              <th style={{ maxWidth: 40 }}>Total</th>
              <th style={{ maxWidth: 50 }}>H.day</th>
              <th style={{ maxWidth: 50 }}>Anch</th>
              <th style={{ maxWidth: 53 }}>OS</th>
              <th style={{ maxWidth: 48 }}>DryD</th>
              <th style={{ maxWidth: 50 }}>
                Team
                <br />
                Lead
              </th>
              <th style={{ maxWidth: 45 }}>Dept Mgr</th>
              <th style={{ maxWidth: 60 }}>
                Final
                <br />
                Status
              </th>
              <th style={{ maxWidth: 65 }}>
                Process
                <br />
                Status
              </th>
              <th style={{ maxWidth: 100 }}>Comments</th>
            </tr>
          </thead>
          <tbody>
            {timesheetsData?.map((timesheet, index) => (
              <tr
                key={timesheet.timesheet_id}
                style={{ fontSize: 13 }}
                className={
                  timesheet.timesheet_final_process_status
                    ? "table-success"
                    : ""
                }
              >
                <td style={{  display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <button
                    type="button"
                    className="btn fa fa-eye"
                    style={{ color: "blue", maxWidth:25, marginRight: "5px" }} // Add margin
                    onClick={() => handleViewClick(timesheet)}
                  ></button>

                  {timesheet.timesheet_attendance_id.attendance_sr_upload_location && (
                    <a
                      href={timesheet.timesheet_attendance_id.attendance_sr_upload_location}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn"
                      style={{ color: 'blue', maxWidth:25 }}
                    >
                      <i className="fa-solid fa-file"></i>
                    </a>
                  )}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 85,
                  }}
                >
                  {timesheet.timesheet_date}
                </td>
                <td style={{ fontSize: 10,alignContent: "center", textAlign: "center", maxWidth: 95, overflow: 'hidden', textOverflow: 'clip', whiteSpace: 'nowrap' }}
                  // style={{
                  //   alignContent: "center",
                  //   textAlign: "center",
                  //   maxWidth: 85,
                  // }}
                >
                  {timesheet.emp_name}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 67,
                  }}
                >
                  {timesheet.timesheet_time_start}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 67,
                  }}
                >
                  {timesheet.timesheet_time_signin}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 67,
                  }}
                >
                  {timesheet.timesheet_time_signout}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 67,
                  }}
                >
                  {timesheet.timesheet_time_return}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 51,
                  }}
                >
                  {decimalToTime(timesheet.timesheet_normal_hours)}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 55,
                  }}
                >
                  {decimalToTime(timesheet.timesheet_total_hours)}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 52,
                  }}
                >
                  {decimalToTime(timesheet.timesheet_over_time_hours)}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 58,
                  }}
                >
                  {timesheet.timesheet_expected_amount}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 58,
                  }}
                >
                  {timesheet.timesheet_overtime_amount}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 64,
                  }}
                >
                  {timesheet.timesheet_allowance_amount}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    fontWeight: "bold",
                    maxWidth: 58,
                  }}
                >
                  {timesheet.timesheet_Total_amount}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 46,
                  }}
                >
                  {timesheet.timesheet_is_holiday ? (
                    <span
                      className="fa fa-check badge text-white bg-success m-2"
                      aria-hidden="true"
                    >
                      &nbsp;
                    </span>
                  ) : (
                    <span
                      className="fa fa-times badge text-white bg-secondary m-2"
                      aria-hidden="true"
                    >
                      &nbsp;
                    </span>
                  )}
                </td>

                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 40,
                  }}
                >
                  {timesheet.timesheet_attendance_id.attendance_is_anchorage ? (
                    <span
                      className="fa fa-check badge text-white bg-success m-2"
                      aria-hidden="true"
                    >
                      &nbsp;
                    </span>
                  ) : (
                    <span
                      className="fa fa-times badge text-white bg-secondary m-2"
                      aria-hidden="true"
                    >
                      &nbsp;
                    </span>
                  )}
                </td>

                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 53,
                  }}
                >
                  {timesheet.timesheet_attendance_id.attendance_is_overseas ? (
                    <span
                      className="fa fa-check badge text-white bg-success m-2"
                      aria-hidden="true"
                    >
                      &nbsp;
                    </span>
                  ) : (
                    <span
                      className="fa fa-times badge text-white bg-secondary m-2"
                      aria-hidden="true"
                    >
                      &nbsp;
                    </span>
                  )}
                </td>

                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 40,
                  }}
                >
                  {timesheet.timesheet_attendance_id
                    .attendance_is_dubaidrydock ? (
                    <span
                      className="fa fa-check badge text-white bg-success m-2"
                      aria-hidden="true"
                    >
                      &nbsp;
                    </span>
                  ) : (
                    <span
                      className="fa fa-times badge text-white bg-secondary m-2"
                      aria-hidden="true"
                    >
                      &nbsp;
                    </span>
                  )}
                </td>

                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 40,
                  }}
                >
                  {timesheet.timesheet_teamlead_approve ? (
                    <span className="fa-solid fa-thumbs-up badge bg-success m-2">
                      &nbsp;
                    </span>
                  ) : (
                    <span className="badge bg-warning text-dark m-2 fa-solid fa-thumbs-down">
                      &nbsp;
                    </span>
                  )}
                </td>

                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 40,
                  }}
                >
                  {timesheet.timesheet_dept_manager_approve ? (
                    <span className="fa-solid fa-thumbs-up badge bg-success">
                      &nbsp;
                    </span>
                  ) : (
                    <span className="badge bg-warning text-dark fa-solid fa-thumbs-down">
                      &nbsp;
                    </span>
                  )}
                </td>

                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 40,
                  }}
                >
                  {!timesheet.timesheet_dept_manager_approve ? (
                    <button
                      type="button"
                      className="btn btn-outline-danger p-1 m-1"
                      style={{ maxHeight: 30, fontSize: 10, margin:2 }}
                    >
                      Waiting
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-outline-success p-1 m-1"
                      style={{ maxHeight: 30, fontSize: 10, margin: 2 }}
                    >
                      Accepted
                    </button>
                  )}
                </td>

                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 40,
                  }}
                >
                  {!timesheet.timesheet_final_process_status ? (
                    <button
                      type="button"
                      className="btn btn-danger p-1 m-1"
                      style={{ maxHeight: 30, fontSize: 10, margin: 2 }}
                      onClick={() => handleProcessClick(timesheet)}
                      disabled={
                        !timesheet.timesheet_total_hours &&
                        timesheet.timesheet_total_hours !== 0
                      } // Disable the button if timesheet_total_hours is null or undefined
                    >
                      Waiting
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-success p-1 m-1 "
                      style={{
                        maxHeight: 30,
                        fontSize: 10,
                        alignContent: "center",
                        textAlign: "center",
                      }}
                      onClick={() => handleProcessClick(timesheet)}
                      disabled={
                        !timesheet.timesheet_total_hours &&
                        timesheet.timesheet_total_hours !== 0.0
                      } // Disable the button if timesheet_total_hours is null or undefined
                    >
                      Completed
                    </button>
                  )}
                </td>
                <td      style={{
                        maxWidth: 130,
                        fontSize: 11,
                        
                      }}>{timesheet.timesheet_comments}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onNextPage={() => handlePageChange(currentPage + 1)}
        onPrevPage={() => handlePageChange(currentPage - 1)}

      />
      {selectedTimesheet && (
        <DetailedUserView
          timesheet={selectedTimesheet}
          onUpdate={handleSaveEdit}
          onClosePopup={handleCloseEdit}
        />
      )}
    </div>
  );
};
export default TeamLeadControl;
