import Base from "./Base";
import React from "react";
import WhatsAppButton from "./WhatsAppButton";
// import BetaMessage from "./BetaMessage";

export default function Home() {
  const customShadow = '-8px -8px 18px #06bfee, 8px 8px 8px #095370';
  // const [showBetaMessage, setShowBetaMessage] = useState(true);

  // const closeBetaMessage = () => {
  //   setShowBetaMessage(false);
  // };

  return (
    <div>
      <Base description="" title="" box_Shadow={customShadow}>
        <div style={{ textAlign: "right", color: "white" }}>
          {/* {showBetaMessage && <BetaMessage onClose={closeBetaMessage} />} */}
          <h1>Maritech Solutions Worldwide</h1>
        </div>
        <WhatsAppButton />
      </Base>
    </div>
  );
}

