import React, { useState, useEffect } from "react";
import CountryDropdown from "../user/CountryDropdown";
import DepartmentDropdown from "../user/DepartmentDropdown";
import BranchDropdown from "../user/BranchDropdown";
import { createWork } from "./helper/coreapicalls";
import { isAuthenticated } from "../auth/helper";

const CreateWorkForm = () => {
  const [formData, setFormData] = useState({
    work_country_id: "",
    work_dept_id: "",
    work_branch_id: "",
    work_vessel: "",
    work_vessel_imo: "",
    work_job_number: "",
    work_job_description: "",
    work_job_pic_name: "",
    work_location: "",
    work_date: "",
    work_type: "",
    work_added_emp: "",
    work_active: false,
  });

  const user = isAuthenticated();
  const [loading, setLoading] = useState(false);

  const loadingMessage = () => {
    return (
      loading && (
        <div className="overlay">
          <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="spinner-border text-danger" role="status"></div>
          </div>
        </div>
      )
    );
  };

  useEffect(() => {
    if (user && user.id) {
      // console.log("printing use effect addwork form");
      setFormData((prevFormData) => ({
        ...prevFormData,
        work_added_emp: user.id,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCountryChange = (selectedCountry) => {
    setFormData({
      ...formData,
      work_country_id: selectedCountry ? selectedCountry.country_id : "",
    });
  };

  const handleDepartmentChange = (selectedDepartment) => {
    setFormData({
      ...formData,
      work_dept_id: selectedDepartment ? selectedDepartment.department_id : "",
    });
  };

  const handleBranchChange = (selectedBranch) => {
    setFormData({
      ...formData,
      work_branch_id: selectedBranch ? selectedBranch.branch_id : "",
    });
  };

  const handleChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData({
      ...formData,
      [e.target.name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form validation
    if (!/^\d+$/.test(formData.work_job_number)) {
      alert("Please enter only digits in the job number field.");
      return;
    }
    if (!formData.work_vessel) {
      alert("Please fill Vessel name");
      return;
    }
    if (!formData.work_job_number) {
      alert("Please fill job number");
      return;
    }
    if (!formData.work_job_description) {
      alert("Please fill job description");
      return;
    }

    try {
      setLoading(true);
      const response = await createWork(formData);
      setLoading(false);
      alert("Work created successfully!", response.message);

      // Reset form fields after successful submission
      setFormData({
        ...formData,
        work_vessel: "",
        work_vessel_imo: "",
        work_job_number: "",
        work_job_description: "",
        work_job_pic_name: "",
        work_location: "",
        work_date: "",
        work_type: "",
        work_added_emp: user.id,
        work_active: false,
      });
    } catch (error) {
      setLoading(false);
      let userErrorMessage = "An error occurred while creating work.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        userErrorMessage = error.response.data.message;
      } else if (error.message) {
        userErrorMessage = error.message;
      }
      console.log("Error message from component", userErrorMessage);
    }
  };

  return (
    <div className=" mt-4 p-3" style={{ borderRadius: "15px" }}>
      {loading && loadingMessage()}
      <div className="box container mt-4 mb-4" style={{ maxWidth: 800 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            minWidth: "auto",
          }}
        >
          <h3>Create New Work</h3>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row m-1 mt-3 ">
            <div className="col-md-4 mb-1 d-flex justify-content-between align-items-center">
              <label htmlFor="work_country_id" className="form-label">
                Country
                <CountryDropdown
                  onSelectCountry={handleCountryChange}
                  defaultValue={formData.work_country_id}
                />
              </label>
            </div>
            <div className="col-md-4 mb-1 d-flex justify-content-between align-items-center">
              <label htmlFor="work_dept_id" className="form-label">
                Department
                <DepartmentDropdown
                  onSelectDepartment={handleDepartmentChange}
                  defaultValue={formData.work_dept_id}
                />
              </label>
            </div>
            <div className="col-md-4 mb-1 d-flex justify-content-between align-items-center">
              <label htmlFor="work_branch_id" className="form-label">
                Branch (Database)
                <BranchDropdown
                  onSelectBranch={handleBranchChange}
                  defaultValue={formData.work_branch_id}
                />
              </label>
            </div>
          </div>
          <div className="row m-1" style={{ maxWidth: 800 }}>
            <div className="col-md-4 mb-3">
              <label htmlFor="work_vessel" className="form-label "></label>
              <input
                placeholder="Vessel Name"
                style={{ maxWidth: 800 }}
                type="text"
                className="form-control input-box"
                id="work_vessel"
                name="work_vessel"
                value={formData.work_vessel}
                onChange={handleChange}
              />
            </div>

            <div className="col-md-4 mb-3">
              <label htmlFor="work_vessel_imo" className="form-label"></label>
              <input
                placeholder="Vessel IMO"
                style={{ maxWidth: 800 }}
                type="text"
                className="form-control input-box"
                id="work_vessel_imo"
                name="work_vessel_imo"
                value={formData.work_vessel_imo}
                onChange={handleChange}
              />
            </div>

            <div className="col-md-4 mb-3">
              <label htmlFor="work_job_number" className="form-label"></label>
              <input
                placeholder=" Job NO. (Sales Order No)"
                style={{ maxWidth: 800 }}
                type="text"
                className="form-control input-box"
                id="work_job_number"
                name="work_job_number"
                value={formData.work_job_number}
                onChange={handleChange}
              />
            </div>

            <div className=" mb-3">
              <label
                htmlFor="work_job_description"
                className="form-label"
              ></label>
              <textarea
                placeholder="Job Description (Max 500 characters)"
                style={{ maxWidth: 800 }}
                className="form-control input-box"
                id="work_job_description"
                name="work_job_description"
                value={formData.work_job_description}
                onChange={handleChange}
              />
            </div>

            <div className="col-md-4 mb-3">
              <label htmlFor="work_job_pic_name" className="form-label"></label>
              <input
                placeholder="PIC Name"
                style={{ maxWidth: 800 }}
                type="text"
                className="form-control input-box"
                id="work_job_pic_name"
                name="work_job_pic_name"
                value={formData.work_job_pic_name}
                onChange={handleChange}
              />
            </div>

            <div className="col-md-4 mb-3">
              <label htmlFor="work_location" className="form-label"></label>
              <input
                placeholder="Work Location"
                style={{ maxWidth: 800 }}
                type="text"
                className="form-control input-box"
                id="work_location"
                name="work_location"
                value={formData.work_location}
                onChange={handleChange}
              />
            </div>

            <div className="col-md-4 mb-3">
              <label htmlFor="work_date" className="form-label"></label>
              <input
                placeholder="Work Date"
                style={{ maxWidth: 800 }}
                type="date"
                className="form-control input-box"
                id="work_date"
                name="work_date"
                value={formData.work_date}
                onChange={handleChange}
              />
            </div>

            <div className="col-md-4 mb-3">
              <label htmlFor="work_type" className="form-label"></label>
              <input
                placeholder=" Work Type"
                style={{ maxWidth: 800 }}
                type="text"
                className="form-control input-box"
                id="work_type"
                name="work_type"
                value={formData.work_type}
                onChange={handleChange}
              />
            </div>

            <div className="col-md-4 mb-3" style={{ alignContent: "end" }}>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input "
                  id="work_active"
                  name="work_active"
                  checked={formData.work_active}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="work_active">
                  Work Active
                </label>
              </div>
            </div>
            <div className="mb-3 d-flex justify-content-center">
              <button type="submit" className="btn1 btn-success btn-block ">
                Create Work
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateWorkForm;
