import React, { useEffect, useState } from 'react';
import { getAllUsers, updateUser } from './helper/coreapicalls';
import { Modal, Button } from 'react-bootstrap';

const DateControl = () => {
  const [allUsersDateList, setAllUsersDateList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editDays, setEditDays] = useState(0);
  const [editDate, setEditDate] = useState('');
   const [loading, setLoading] = useState(false);


   const loadingMessage = () => {
    return (
      loading && (
        <div className="overlay">
          <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="spinner-border text-danger" role="status"></div>
          </div>
        </div>
      )
    );
  };
  useEffect(() => {
    console.error("date control useffect");
    const fetchData = async () => {
        setLoading(true);
      try {
        const allUsersFromAPI = await getAllUsers();
        setAllUsersDateList(allUsersFromAPI);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching users:", error);
      }
    };
    fetchData();
  }, []);

  const calculateDate = (days) => {
    const today = new Date();
    const calculatedDate = new Date(today);
    calculatedDate.setDate(today.getDate() - days + 1);
    const day = String(calculatedDate.getDate()).padStart(2, '0');
    const month = String(calculatedDate.getMonth() + 1).padStart(2, '0');
    const year = calculatedDate.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const handleEditClick = (user) => {
    setSelectedUser(user);
    setEditDays(user.emp_date_allowed);
    setEditDate(calculateDate(user.emp_date_allowed));
    setShowModal(true);
  };

  const handleDaysChange = (e) => {
    const days = parseInt(e.target.value, 10);
    setEditDays(days);
    setEditDate(calculateDate(days));
  };

  const handleDateChange = (e) => {
    setEditDate(e.target.value);
    const selectedDate = new Date(e.target.value);
    const today = new Date();
    const diffTime = Math.ceil((today - selectedDate) / (1000 * 60 * 60 * 24));
    setEditDays(diffTime + 1);
  };

  const handleSubmit = async () => {
    // console.log("Updated User:", { id: selectedUser.id, editDays, editDate });
    try {
        setLoading(true);
      await updateUser(selectedUser.id, { date_allowed_temp: editDays });

      // Update the user data in the list
      setAllUsersDateList((prevList) =>
        prevList.map((user) =>
          user.id === selectedUser.id ? { ...user, emp_date_allowed: editDays } : user
        )
      );

      setShowModal(false);
      setLoading(false);

      window.alert(`User allowed date updated to: ${editDays} Days!!
          Automatic reset in 24 hours.`);

    } catch (error) {
        setLoading(false);
      console.error("Error updating user:", error);
    }
  };

  return (
    <div>
         {loadingMessage()}
      <table className="table table-striped" style={{ maxWidth: 600 }}>
        <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
          <tr style={{ fontSize: 12, textAlign: "center" }}>
            <th>Name</th>
            <th>Date Allowed</th>
            <th>Allowed From</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {allUsersDateList?.map((user) => (
            <tr key={user.id} style={{ padding:'3px'}}  className={user.emp_date_allowed !== 3 ? "table-danger" : "" 
            }>
              <td style={{ textAlign: "center", fontSize:10 }}>{user.emp_name}</td>
              <td style={{ textAlign: "center", fontSize:10 }}>{user.emp_date_allowed}</td>
              <td style={{ textAlign: "center", fontSize:10 }}>{calculateDate(user.emp_date_allowed)}</td>
              <td style={{ textAlign: "center"}}>
              <button
                      type="button"
                      className="btn btn-outline-success "
                      style={{ maxWidth: 65, fontSize: 11 , maxHeight: 35}}
                      onClick={() => handleEditClick(user)}
                    >Edit</button>
              
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Edit Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Edit - Number of Days</label>
            <input
              type="number"
              className="form-control"
              value={editDays}
              onChange={handleDaysChange}
            />
          </div>
          <div className="form-group mt-2">
            <label>Edit Date</label>
            <input
              type="date"
              className="form-control"
              value={editDate}
              onChange={handleDateChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
          <Button variant="primary" onClick={handleSubmit}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DateControl;
