import React, { useEffect, useState } from "react";
import { isAuthenticated, userRole } from "../auth/helper";
import {
  getTimesheetofUser,
  getAllTimesheetsByFilter,
  generateReportApi,
} from "../core/helper/coreapicalls";
import TimesheetNotSavedList from "./TimesheetNotSavedList";
import WorkListUser from "./WorkListUser";
import AllTimesheetOfSingleUser from "../core/AllTimesheetOfSingleUser";
import Menu from "../core/Menu";
import Footer from "../core/Footer";
import TeamLeadControl from "../core/TeamLeadControl";
import WhatsAppButton from "../core/WhatsAppButton";
import Pagination from "../core/Pagination";
import TimesheetSearch from "../core/TimesheetSearch";
import { useNavigate } from "react-router-dom";

const UserDashboard = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [showWorkList, setShowWorkList] = useState(false);
  const [addTimesheet, setAddTimesheet] = useState(false);
  const [timesheetData, setTimesheetData] = useState(null);
  const [teamleadData, setTeamLeadData] = useState(false);
  const [allTimesheetsData, setAllTimesheetsData] = useState(null);
  const [timesheetCount, setTimesheetCount] = useState(0);
  const [showAllTimesheets, setShowAllTimesheets] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});

  const loadingMessage = () =>
    loading && (
      <div className="overlay">
        <div className="d-flex justify-content-center align-items-center vh-100">
          <div className="spinner-border text-danger" role="status"></div>
        </div>
      </div>
    );

  const onDeleteAttendance = (attendance) => {
    // console.log('Deleted attendance:', attendance); // Log deleted attendance
    setShowAllTimesheets(false);
    navigate(0);
  };

  const handleSearch = async (searchFilters) => {
    setFilters(searchFilters);
    setCurrentPage(1);
    await fetchTimesheets(1, searchFilters);
  };
  const handleReportGen = async (searchFilters) => {
    setFilters(searchFilters);
    setCurrentPage(1);
    await generateReport(1, searchFilters);
  };

  const fetchTimesheets = async (
    page = 1,
    searchFilters = filters,
    teamId = userData.team_id_tl || userData.team_id_sl
  ) => {
    // console.log("working fetchTimesheets")
    try {
      setLoading(true);
      // console.log("Search Filters", searchFilters.OT)
      const result = await getAllTimesheetsByFilter(
        searchFilters.teamlead === true ? searchFilters.teamlead : null,
        searchFilters.manager === true ? searchFilters.manager : null,
        searchFilters.sr === true ? searchFilters.sr : null,
        teamId? teamId: null,
        searchFilters.branchId && searchFilters.branchId.branch_id
          ? searchFilters.branchId.branch_id
          : null,
        page,
        searchFilters.msid,
        searchFilters.eng_name,
        searchFilters.vessel_name,
        searchFilters.startdate,
        searchFilters.enddate,
        searchFilters.Job_No,
        searchFilters.OT === true ? searchFilters.OT : null,
      );
   
      setTimesheetCount(result.count)
      setAllTimesheetsData(result.timesheetsData);
      setTotalPages(Math.ceil(result.count / 100));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching timesheet data:", error);
      setLoading(false);
    }
  };

  const generateReport = async (
    page = 1,
    searchFilters = filters,
    teamId = userData.team_id_tl || userData.team_id_sl
  ) => {
    try {
      setLoading(true);
      console.log("Generateee  Filters");
      const result = await generateReportApi(
        searchFilters.teamlead === true ? searchFilters.teamlead : null,
        searchFilters.manager === true ? searchFilters.manager : null,
        searchFilters.sr === true ? searchFilters.sr : null,
        teamId? teamId: null,
        searchFilters.branchId && searchFilters.branchId.branch_id
          ? searchFilters.branchId.branch_id
          : null,
        page,
        searchFilters.msid,
        searchFilters.eng_name,
        searchFilters.vessel_name,
        searchFilters.startdate,
        searchFilters.enddate,
        searchFilters.Job_No,
        searchFilters.OT === true ? searchFilters.OT : null,
      );
      downloadFileFromResult(result);
      // setAllTimesheetsData(result.timesheetsData);
      // setTotalPages(Math.ceil(result.count / 100));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching timesheet data:", error);
      setLoading(false);
    }
  };

  const downloadFileFromResult = ({ blob, headers }) => {
    // Create a new Blob object using the response data (the Excel file)
    const fileBlob = new Blob([blob], { type: headers.get("content-type") });

    // Create a temporary anchor element to trigger the download
    const url = window.URL.createObjectURL(fileBlob);
    const link = document.createElement("a");
    link.href = url;

    // Get the filename from the Content-Disposition header if available
    const contentDisposition = headers.get("content-disposition");
    let fileName = "downloaded_file.xlsx"; // Default filename
    if (contentDisposition && contentDisposition.includes("filename=")) {
      fileName = contentDisposition
        .split("filename=")[1]
        .split(";")[0]
        .replace(/"/g, ""); // Extract the filename
    }

    // Set the file name for the downloaded file
    link.setAttribute("download", fileName);

    // Append the link to the document body and click it to start the download
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const normalUserfilterConfig = [
    { type: "text", name: "msid", label: "MSID" },
    { type: "text", name: "eng_name", label: "Eng_Name" },
    { type: "text", name: "vessel_name", label: "Vessel_Name" },
    { type: "text", name: "Job_No", label: "Job_No" },
    { type: "date", name: "startdate", label: "Start" },
    { type: "date", name: "enddate", label: "End" },
    { type: "checkbox", name: "OT", label: "Overtime" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const teamLead = userRole();
        const isTeamLead = teamLead.includes("tl") || teamLead.includes("sl");
        setTeamLeadData(isTeamLead);

        const user = await isAuthenticated();
        if (user) {
          setUserData(user);
          if (isTeamLead && (user.team_id_tl || user.team_id_sl)) {
            await fetchTimesheets(
              currentPage,
              filters,
              user.team_id_tl || user.team_id_sl
            );
          }
        } else {
          console.log("User data not found");
        }

        try {
          const timesheetData = await getTimesheetofUser(user.id);
          setTimesheetData(timesheetData.results);
        } catch (error) {
          console.error("Error fetching user timesheet data:", error);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error in fetchData:", error);
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);


  const handleShowWorkList = () => {
    setShowWorkList(!showWorkList);
    setAddTimesheet(false);
    setShowAllTimesheets(false);
  };

  const handleShowAllTimesheets = () => {
    setShowAllTimesheets(!showAllTimesheets);
    setShowWorkList(false);
    setAddTimesheet(false);
  };

  const handleAddTimesheet = () => {
    setAddTimesheet(!addTimesheet);
    setShowWorkList(false);
    setShowAllTimesheets(false);
  };

  const handleUpdateTimesheet = async (updatedTimesheet) => {
    try {
      setTimesheetData((prevData) => {
        const updatedList = prevData.map((timesheet) =>
          timesheet.timesheet_id === updatedTimesheet.timesheet_id
            ? updatedTimesheet
            : timesheet
        );
        return updatedList;
      });
    } catch (error) {
      console.error("Error updating timesheet:", error);
    }
  };

  const handlePageChange = async (newPage) => {
    setCurrentPage(newPage);
    if (userData.team_id_tl || userData.team_id_sl) {
      await fetchTimesheets(
        newPage,
        filters,
        userData.team_id_tl || userData.team_id_sl
      );
    }
  };

  return (
    <div className="dashboard-wrapper">
      {loading && loadingMessage()}

      {userData && (
        <div className="container-grow">
          <div>
            <Menu />
            <WhatsAppButton />

            <div
              className="d-flex flex-row align-items-center"
              style={{ marginLeft: "24px", fontSize: 12 }}
            >
              <div className="p-1 fw-bold">Welcome,</div>
              <div className="p-1 fw-bold text-black">{userData.emp_name}</div>
            </div>
            {teamleadData && (
              <div className="row m-2">
                <div className="col-md-12">
                  <div className="row">
                    <div className="d-flex flex-column flex-md-row align-items-start">
                      <button
                        className="btn btn-primary"
                        style={{ marginRight: "15px", marginBottom: "5px" }}
                        onClick={handleShowWorkList}
                      >
                        {showWorkList ? "WorkList ⬆️" : "WorkList ⬇️ "}
                      </button>

                      {timesheetData && timesheetData.length > 0 && (
                        <button
                          style={{ marginRight: "15px", marginBottom: "5px" }}
                          className="btn btn-primary"
                          onClick={handleAddTimesheet}
                        >
                          {addTimesheet ? "New-TS ⬆️" : " New-TS ⬇️"}
                        </button>
                      )}

                      <button
                        className="btn btn-primary "
                        style={{ marginRight: "15px", marginBottom: "5px" }}
                        onClick={handleShowAllTimesheets}
                      >
                        {showAllTimesheets ? "All-TS ⬆️" : "All-TS ⬇️ "}
                      </button>
                    </div>
                    {!showAllTimesheets && !showWorkList && (
                      <div className="d-flex pl-0 ml-0 justify-content-start">
                        <div className="mt-2 ">
                          <div>
                            <TimesheetSearch
                              initialConfig={normalUserfilterConfig}
                              onSearch={handleSearch}
                              onReportGen={handleReportGen}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {showAllTimesheets && (
                    <div>
                      <div className="col-xl-3 col-md-12 mt-4">
                        <div
                          className="card border-left-primary shadow-lg"
                          style={{
                            width: 200,
                            backgroundColor: "DarkSlateBlue",
                          }}
                        >
                          <div className="card-body">
                            <div className="row no-gutters align-items-center">
                              <div className="col mr-2">
                                <div
                                  className="font-weight-bold text-white"
                                  style={{ fontSize: "14px" }}
                                >
                                  All Timesheets of {userData.emp_name}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <AllTimesheetOfSingleUser
                        userid={userData.id}
                        onDelete={onDeleteAttendance}
                      />
                    </div>
                  )}
                  {showWorkList && (
                    <div className="bg-body mt-5 p-3">
                      <WorkListUser />
                    </div>
                  )}
                  <div className="mt-3">
                    {addTimesheet && timesheetData && (
                      <TimesheetNotSavedList
                        timesheetData={timesheetData.filter(
                          (timesheet) => !timesheet.timesheet_save_state
                        )}
                        onUpdate={handleUpdateTimesheet}
                      />
                    )}
                  </div>
                </div>
                {!showAllTimesheets && (
                  <div className="col-md-12">
                    {allTimesheetsData && allTimesheetsData.length > 0 && (
                     
                      <>
         
                        <TeamLeadControl
                          totalTimesheetsCount= {timesheetCount}
                          timesheetsData={allTimesheetsData}
                          onUpdateTimesheets={handleUpdateTimesheet}
                        />
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onNextPage={() => handlePageChange(currentPage + 1)}
                          onPrevPage={() => handlePageChange(currentPage - 1)}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
            )}

            {!teamleadData && (
              <div
                className="row"
                style={{ backgroundColor: "LightCyan", margin: 2 }}
              >
                <div className="col-md-12">
                  <div>
                    <button
                      className="btn btn-primary m-3"
                      onClick={handleShowWorkList}
                    >
                      {showWorkList ? "Works ⬆️" : "Works ⬇️ "}
                    </button>
                    {timesheetData && timesheetData.length > 0 && (
                      <button
                        className="btn btn-primary m-3"
                        onClick={handleAddTimesheet}
                      >
                        {addTimesheet ? "New-Ts⬆️" : " New-Ts ⬇️"}
                      </button>
                    )}
                    <button
                      className="btn btn-primary m-3"
                      onClick={handleShowAllTimesheets}
                    >
                      {showAllTimesheets ? "All-Ts⬆️" : "All-Ts ⬇️"}
                    </button>
                  </div>
                  {showAllTimesheets && (
                    <AllTimesheetOfSingleUser
                      userid={userData.id}
                      onDelete={onDeleteAttendance}
                    />
                  )}

                  {showWorkList && <WorkListUser />}
                  <div>
                    {addTimesheet && timesheetData && (
                      <TimesheetNotSavedList
                        timesheetData={timesheetData.filter(
                          (timesheet) => !timesheet.timesheet_save_state
                        )}
                        onUpdate={handleUpdateTimesheet}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default UserDashboard;
