import React, { useState, useEffect } from 'react';
import ApproveFormManager from './ApproveFormManager';
import { deleteAttendance, getAllTimesheets } from './helper/coreapicalls';
import Pagination from './Pagination'; // Import the Pagination component





const AllTimesheetOfSingleUser = (props) => {
  const [selectedTimesheet, setSelectedTimesheet] = useState(null);
  const [selecteduserID, setSelecteduserID] = useState(null);
  const [loading, setLoading] = useState(true);
  const [timesheetsData, setTimesheetsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);



  const DeleteClick = async (attendance) => {
    const confirmation = window.confirm(
      `Are you sure you want to delete the attendance for ${attendance.timesheet_work_id.work_vessel} with job number ${attendance.timesheet_work_id.work_job_number}?`
    );
    if (!confirmation) return; // Exit if user cancels
    try {
      setLoading(true);
      const response = await deleteAttendance(attendance.timesheet_attendance_id.attendance_id);
      if (response.success) {
        setLoading(false);
        window.alert('Attendance deleted successfully!');
        props.onDelete(attendance);  // This passes the deleted attendance back to the parent
      }
    } catch (error) {
      setLoading(false);
      window.alert(`Error deleting attendance: ${error.message}`);
    }
  };


  const loadingMessage = () => {
    return (
      loading && (
        <div className="overlay">
          <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="spinner-border text-danger" role="status"></div>
          </div>
        </div>
      )
    );
  };
  useEffect(() => {
    setSelecteduserID(props.userid); // Set the selected user id when props.userid changes

  }, [props.userid]);

  // useEffect(() => {

  // }, [selecteduserID]);

  useEffect(() => {
    // Fetch data only if both currentPage and selecteduserID are not null
    if (currentPage !== null && selecteduserID !== null) {
      fetchData(currentPage, selecteduserID);
    }
  }, [currentPage, selecteduserID]);

  function decimalToTime(decimalHours) {
    // Split the decimal into hours and minutes
    var hours = Math.floor(decimalHours);
    var minutes = Math.round((decimalHours - hours) * 60);

    // Format the time
    var formattedTime = hours + ":" + (minutes < 10 ? '0' : '') + minutes;

    return formattedTime;
  }

  const fetchData = async (page, selecteduserID) => {
    setLoading(true);
    const response = await getAllTimesheets(page, selecteduserID);
    setTimesheetsData(response.timesheetsData);
    setTotalPages(Math.ceil(response.count / 100)); // Assuming 10 items per page
    setLoading(false);
  };
  // const handleEditClick = (timesheet) => {
  //   setSelectedTimesheet(timesheet);
  // };

  const handleSaveEdit = (editedTimesheet) => {
    setTimesheetsData((prevData) =>
      prevData.map((timesheet) =>
        timesheet.timesheet_id === editedTimesheet.timesheet_id ? editedTimesheet : timesheet
      )
    );
    setSelectedTimesheet(null);
  };

  const handleCloseEdit = () => {
    setSelectedTimesheet(null);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  return (

    <div className="row mt-2">
      {loadingMessage()}
      <div className="table-container" style={{ maxHeight: '500px', overflowY: 'auto' }}>
        <table className="table table-striped">
          <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
            <tr
              style={{
                maxHeight: 30,
                fontSize: 12,
                alignContent: "center",
                textAlign: "center",
              }}
            >
              {/* <th style={{ maxWidth: 30 }}>SR</th> */}
              <th style={{ maxWidth: 30 }}>Actions</th>
              <th style={{ maxWidth: 85 }}>
                Date
                <br />
                y-m-d
              </th>
              <th style={{ maxWidth: 85 }}>
                Eng
                <br />
                Name
              </th>
              <th style={{ maxWidth: 85 }}>
                Vessel
                <br />
                Name
              </th>
              <th style={{ maxWidth: 65 }}>
                Start
                <br />
                Time
              </th>
              <th style={{ maxWidth: 65 }}>
                Sign
                <br />
                In
              </th>
              <th style={{ maxWidth: 65 }}>
                Sign
                <br />
                Out
              </th>
              <th style={{ maxWidth: 65 }}>Return</th>
              <th style={{ maxWidth: 58 }}>
                Normal
                <br />
                Hrs
              </th>
              <th style={{ maxWidth: 45 }}>
                Total
                <br />
                Hrs
              </th>
              <th style={{ maxWidth: 52 }}>
                OT
                <br />
                Hrs
              </th>
              <th style={{ maxWidth: 45 }}>
                Exp.
                <br />
                OT
              </th>
              <th style={{ maxWidth: 59 }}>
                OT
                <br />
                Amt
              </th>
              <th style={{ maxWidth: 72 }}>Alw.Amt</th>
              <th style={{ maxWidth: 40 }}>Total</th>
              <th style={{ maxWidth: 50 }}>H.day</th>
              <th style={{ maxWidth: 50 }}>Anch</th>
              <th style={{ maxWidth: 53 }}>OS</th>
              <th style={{ maxWidth: 48 }}>DryD</th>
              <th style={{ maxWidth: 50 }}>
                Team
                <br />
                Lead
              </th>
              <th style={{ maxWidth: 45 }}>Dept Mgr</th>
              <th style={{ maxWidth: 56 }}>
                Final
                <br />
                Status
              </th>
              <th style={{ maxWidth: 65 }}>
                Process
                <br />
                Status
              </th>
              <th style={{ maxWidth: 100 }}>Comments</th>
            </tr>
          </thead>
          <tbody>
            {timesheetsData?.map((timesheet, index) => (
              <tr
                key={timesheet.timesheet_id}
                style={{ fontSize: 13 }}
                className={
                  timesheet.timesheet_dept_manager_approve ? "table-success" : ""
                }
              >
                {/* <td>
                  <button
                    type="button"
                    className="btn fa fa-eye"
                    style={{ color: "blue", maxWidth: 30 }}
                    // onClick={() => handleViewClick(timesheet)}
                  ></button>

                  {timesheet.timesheet_attendance_id
                    .attendance_sr_upload_location ? (
                    <a
                      href={
                        timesheet.timesheet_attendance_id
                          .attendance_sr_upload_location
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn"
                      style={{ color: "blue", maxWidth: 30 }}
                    >
                      <i class="fa-solid fa-file"></i>
                    </a>
                  ) :
                    <button
                      type="button"
                      className="btn fa fa-add"
                      style={{ color: "blue", maxWidth: 30 }}
                    // onClick={() => handleEditClick(timesheet)}
                    >
                    </button>
                  }
                </td> */}
                <td>
                  {(!timesheet.timesheet_dept_manager_approve && !timesheet.timesheet_teamlead_approve) && (
                    <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                      {/* <button
                        type="button"
                        className="btn fa fa-edit"
                        style={{ color: "blue", maxWidth: 30, flex: 1 }}
                      // onClick={() => handleEditClick(timesheet)}
                      >
                      </button> */}
                      <button

                        type="button"
                        className="btn fa fa-trash-o"
                        style={{ color: "red", maxWidth: 30, flex: 1 }}
                        onClick={() => DeleteClick(timesheet)}
                      >

                      </button>
                    </div>
                  )}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 85,
                  }}
                >
                  {timesheet.timesheet_date}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 85,
                  }}
                >
                  {timesheet.emp_name}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 85,
                  }}
                >
                  {timesheet.timesheet_work_id.work_vessel}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 80,
                  }}
                >
                  {timesheet.timesheet_time_start}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 67,
                  }}
                >
                  {timesheet.timesheet_time_signin}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 67,
                  }}
                >
                  {timesheet.timesheet_time_signout}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 67,
                  }}
                >
                  {timesheet.timesheet_time_return}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 51,
                  }}
                >
                  {decimalToTime(timesheet.timesheet_normal_hours)}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 55,
                  }}
                >
                  {decimalToTime(timesheet.timesheet_total_hours)}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 52,
                  }}
                >
                  {decimalToTime(timesheet.timesheet_over_time_hours)}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 58,
                  }}
                >
                  {timesheet.timesheet_expected_amount}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 58,
                  }}
                >
                  {timesheet.timesheet_overtime_amount}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 64,
                  }}
                >
                  {timesheet.timesheet_allowance_amount}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    fontWeight: "bold",
                    maxWidth: 58,
                  }}
                >
                  {timesheet.timesheet_Total_amount}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 46,
                  }}
                >
                  {timesheet.timesheet_is_holiday ? (
                    <span
                      className="fa fa-check badge text-white bg-success m-2"

                    >
                      &nbsp;
                    </span>
                  ) : (
                    <span
                      className="fa fa-times badge text-white bg-secondary m-2"

                    >
                      &nbsp;
                    </span>
                  )}
                </td>

                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 40,
                  }}
                >
                  {timesheet.timesheet_attendance_id.attendance_is_anchorage ? (
                    <span
                      className="fa fa-check badge text-white bg-success m-2"

                    >
                      &nbsp;
                    </span>
                  ) : (
                    <span
                      className="fa fa-times badge text-white bg-secondary m-2"

                    >
                      &nbsp;
                    </span>
                  )}
                </td>

                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 53,
                  }}
                >
                  {timesheet.timesheet_attendance_id.attendance_is_overseas ? (
                    <span
                      className="fa fa-check badge text-white bg-success m-2"

                    >
                      &nbsp;
                    </span>
                  ) : (
                    <span
                      className="fa fa-times badge text-white bg-secondary m-2"

                    >
                      &nbsp;
                    </span>
                  )}
                </td>

                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 40,
                  }}
                >
                  {timesheet.timesheet_attendance_id
                    .attendance_is_dubaidrydock ? (
                    <span
                      className="fa fa-check badge text-white bg-success m-2"

                    >
                      &nbsp;
                    </span>
                  ) : (
                    <span
                      className="fa fa-times badge text-white bg-secondary m-2"

                    >
                      &nbsp;
                    </span>
                  )}
                </td>

                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 40,
                  }}
                >
                  {timesheet.timesheet_teamlead_approve ? (
                    <span className="fa-solid fa-thumbs-up badge bg-success m-2">
                      &nbsp;
                    </span>
                  ) : (
                    <span className="badge bg-warning text-dark m-2 fa-solid fa-thumbs-down">
                      &nbsp;
                    </span>
                  )}
                </td>

                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 40,
                  }}
                >
                  {timesheet.timesheet_dept_manager_approve ? (
                    <span className="fa-solid fa-thumbs-up badge bg-success  m-2">
                      &nbsp;
                    </span>
                  ) : (
                    <span className="badge bg-warning text-dark fa-solid fa-thumbs-down  m-2">
                      &nbsp;
                    </span>
                  )}
                </td>

                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 55,
                  }}
                >
                  {!timesheet.timesheet_dept_manager_approve ? (
                    <button
                      type="button"
                      className="btn btn-outline-danger "
                      style={{ maxWidth: 65, fontSize: 11, padding: 2 }}
                    >
                      Waiting
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-outline-success "
                      style={{ maxWidth: 65, fontSize: 11, padding: 2 }}
                    >
                      Completed
                    </button>
                  )}
                </td>

                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 55,
                  }}
                >
                  {!timesheet.timesheet_final_process_status ? (
                    <button
                      type="button"
                      className="btn btn-outline-danger m-2"
                      style={{ fontSize: 11, padding: 2 }}
                    >
                      Waiting
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-outline-success m-2"
                      style={{ fontSize: 11, padding: 2 }}
                    >
                      Completed
                    </button>
                  )}
                </td>

                <td>{timesheet.timesheet_comments}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onNextPage={handleNextPage}
        onPrevPage={handlePrevPage}
      />
      {selectedTimesheet && (
        <ApproveFormManager
          timesheet={selectedTimesheet}
          onUpdate={handleSaveEdit}
          onClosePopup={handleCloseEdit}
        />
      )}
    </div>
  );
};
export default AllTimesheetOfSingleUser;



