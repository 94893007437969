import React, { useState } from "react";
import TeamDropdown from "../user/TeamDropdown";

const TimesheetSearch = ({ initialConfig, onSearch, onReportGen }) => {
  const [filters, setFilters] = useState({});

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleTeamChange = (selectedTeamId) => {
    setFilters({ ...filters, teamId: selectedTeamId });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSearch(filters);
  };

  const handleReportGen = (event) => {
    event.preventDefault();
    onReportGen(filters);
  };

  const handleClearFilter = () => {
    setFilters({});
    onSearch({});
  };

  const renderFilter = (filter) => {
    switch (filter.type) {
      case "text":
        return (
          <div  key={filter.name} className="col-md-2 mb-3">
            <label htmlFor={filter.name} className="form-label">
              {filter.label}:
            </label>
            <input
              type="text"
              className="form-control form-control-sm"
              id={filter.name}
              name={filter.name}
              value={filters[filter.name] || ""}
              onChange={handleInputChange}
            />
          </div>
        );
      case "select":
        return (
          <div key={filter.name} className="col-md-2 mb-3 ">
            <label htmlFor={filter.name} className="form-label">
              {filter.label}:
            </label>
            <TeamDropdown
              onSelectTeam={handleTeamChange}
              defaultValue={filters.teamId}
              height={31}
            />
          </div>
        );
      case "date":
        return (
          <div key={filter.name} className="col-md-2 mb-3">
            <label htmlFor={filter.name} className="form-label">
              {filter.label}:
            </label>
            <input
              type="date"
              className="form-control form-control-sm"
              id={filter.name}
              name={filter.name}
              value={filters[filter.name] || ""}
              onChange={handleInputChange}
            />
          </div>
        );
      case "checkbox":
        return (
          <div key={filter.name} className="col-md-2 mb-3">
                          <label className="form-check-label" htmlFor={filter.name}>
                {filter.label}
              </label>
              <input
                type="checkbox"
                className="form-check-input "
                style={{marginTop:8, minWidth:30, minHeight: 30}}
                id={filter.name}
                name={filter.name}
                checked={filters[filter.name] || false}
                onChange={handleInputChange}
              />

          
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="container ">
        <div className="row d-flex flex-wrap" style={{ maxWidth: 600 }}>
          {initialConfig.map((filter) => renderFilter(filter))}
        </div>
        <div className="row mt-3">
          <div className="col-md-2">
            <button
              type="submit"
              className="btn btn-primary w-100"
              style={{ fontSize: 10, padding: "2px 5px" }}
            >
              Search <i className="fas fa-search"></i>
            </button>
          </div>
          <div className="col-md-2">
            <button
              type="button"
              className="btn btn-primary w-100"
              onClick={handleReportGen}
              style={{ fontSize: 10, padding: "2px 5px" }}
            >
              Create Report
            </button>
          </div>
          <div className="col-md-2">
            <button
              type="button"
              className="btn btn-secondary w-100"
              onClick={handleClearFilter}
              style={{ fontSize: 10, padding: "2px 5px" }}
            >
              Clear <i className="fa fa-refresh" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </form>

  );
};

export default TimesheetSearch;