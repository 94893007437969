import React, { useEffect, useState } from 'react';
import { isAuthenticated } from '../auth/helper';
import AddWorkForm from '../core/AddWorkForm';
import AllWorks from './AllWorks';
import Menu from '../core/Menu';

const OpDashboard = () => {
  const [userData, setUserData] = useState(null);
  const [showAddWorkForm, setShowAddWorkForm] = useState(false);
  const [showAllWorks, setShowAllWorks] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  // const [showActiveWorks, setShowActiveWorks] = useState(true);

  
  useEffect(() => {
    const fetchData = async () => {
      const user = await isAuthenticated();
      setUserData(user);
    };

    fetchData();
  }, []);
  // const handleShowActiveWorks = () => {
  //   setShowActiveWorks(true);
  // };

  // const handleShowInactiveWorks = () => {
  //   setShowActiveWorks(false);
  // };
  const handleAddNewWorkButton = () => {
    setShowAddWorkForm(true);
    setShowAllWorks(false);
  };
  const handleShowAllWorksButton = () => {
    setShowAllWorks(true);
    setShowAddWorkForm(false);
  };
  // const customBoxShadow = "-8px -8px 18px #feffff, 8px 8px 8px #161b1d2f";
  return (
    <div className='dashboard-wrapper container-grow '>
      <Menu></Menu>
      <div className='m-3'>

        {userData && (
          <>
            <div className="d-flex flex-row">
              <div className="p-2">Welcome,</div>
              <div className="p-2 ">{userData.emp_email}</div>
              {/* <SearchUser SearchFor="Search Work" /> */}
            </div>
            <button
              type="button"
              className="btn btn-primary m-2"
              onClick={handleAddNewWorkButton}
            >
              Add New Work
            </button>
            <button type="button" className="btn btn-primary m-2" onClick={handleShowAllWorksButton}>
              View All Works
            </button>
            {/* <button type="button" className={`btn m-2 ${showActiveWorks ? 'btn-primary' : 'btn-outline-primary'}`} onClick={handleShowActiveWorks}>
            Active Works
          </button>
          <button type="button" className={`btn m-2 ${!showActiveWorks ? 'btn-primary' : 'btn-outline-primary'}`} onClick={handleShowInactiveWorks}>
            Inactive Works
          </button> */}
             <div  >
              {showAddWorkForm && <AddWorkForm />}
            </div>
           <div >
           {showAllWorks && <AllWorks currentPage={currentPage} setCurrentPage={setCurrentPage} />}
           </div>
            {/* {showAllWorks && <AllWorks showActiveWorks={showActiveWorks} successMessage={successMessage} errorMessage={errorMessage}  />} */}
          </>
        )}
      </div>
    </div>
  );
};

export default OpDashboard;
