



import React, { useState, useEffect } from 'react';
import { getEmpDateAllowed, isAuthenticated } from '../auth/helper';

const AttendanceForm = ({ workDetails, handleCreateAttendance }) => {

  const currentEmployee = isAuthenticated();
  const current_user_id = currentEmployee.id
  const current_user_team_id = currentEmployee.emp_team_id

  const [formData, setFormData] = useState({
    attendance_work: workDetails.work_id,
    attendance_emp: current_user_id,
    attendance_emp_team: current_user_team_id,
    attendance_is_accompanied_by: '',
    attendance_is_anchorage: false,
    attendance_is_dubaidrydock: false,
    attendance_is_overseas: false,
    attendance_remarks: '',
    attendance_is_completed: false,
    attendance_is_not_possible: false,
    attendance_is_sr_available: false,
    attendance_sr_numbers: '',
    attendance_sr_upload_location: '',
    attendance_is_do_available: false,
    attendance_do_numbers: '',
    attendance_do_upload_location: '',
    attendance_date: '',
    attendance_start_date: '',
    attendance_end_date: '',
  });

  const [allowedPreviousDates, setAllowedPreviousDates] = useState(3);
  // const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const allowedPreviousDates = await getEmpDateAllowed();
      setAllowedPreviousDates(allowedPreviousDates);
      // const user = await isAuthenticated();
      // setUserData(user);
    };
    fetchData();
  },
    []
  );

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;

    setFormData((prevData) => {
      let newFormData = { ...prevData, [name]: fieldValue };

      // Clear the attendance_date field if attendance_is_anchorage is checked
      if (name === 'attendance_is_anchorage' && checked) {
        newFormData.attendance_date = '';
      }
      if (name === 'attendance_is_anchorage' && !checked) {
        newFormData.attendance_start_date = '';
        newFormData.attendance_end_date = '';
      }

      if(name ==="attendance_is_do_available"&& !checked){
        newFormData.attendance_do_numbers = '';
        newFormData.attendance_do_upload_location = '';
      }

      if (name === 'attendance_is_sr_available' && !checked) {
        newFormData.attendance_sr_numbers = '';
        newFormData.attendance_sr_upload_location = '';
      }

      return newFormData;
    });
  };

  const handleFileChange = (e, fieldName) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: file,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const currentEmployee = await isAuthenticated();
    const current_user_id = currentEmployee.id;
    const current_user_team_id = currentEmployee.emp_team_id;

    if (current_user_team_id === undefined || current_user_team_id === null) {
      alert("You are not assigned to a team! Contact your Reporting Manager.");
      return null; // or return some placeholder component or message
    }
    // Check if date fields are required and not empty
    if (formData.attendance_is_anchorage && !formData.attendance_start_date) {
      alert('Start date is required.');
      return;
    }
    if (formData.attendance_is_anchorage && !formData.attendance_end_date) {
      alert('End date is required.');
      return;
    }
    if (!formData.attendance_is_anchorage && !formData.attendance_date) {
      alert('Date is required.');
      return;
    }
    // Construct the API request data only if required date fields are present
    const apiRequestData = {
      attendance_work: formData.attendance_work,
      attendance_emp: current_user_id,
      attendance_emp_team: current_user_team_id,
      attendance_is_accompanied_by: formData.attendance_is_accompanied_by,
      attendance_is_anchorage: formData.attendance_is_anchorage,
      attendance_is_dubaidrydock: formData.attendance_is_dubaidrydock,
      attendance_is_overseas: formData.attendance_is_overseas,
      attendance_remarks: formData.attendance_remarks,
      attendance_is_completed: formData.attendance_is_completed,
      attendance_is_not_possible: formData.attendance_is_not_possible,
      attendance_is_sr_available: formData.attendance_is_sr_available,
      attendance_sr_numbers: formData.attendance_sr_numbers,
      attendance_sr_upload_location: formData.attendance_sr_upload_location,
      attendance_is_do_available: formData.attendance_is_do_available,
      attendance_do_numbers: formData.attendance_do_numbers,
      attendance_do_upload_location: formData.attendance_do_upload_location,
      ...(formData.attendance_is_anchorage && {
        attendance_start_date: formData.attendance_start_date,
        attendance_end_date: formData.attendance_end_date,
      }),
      ...(formData.attendance_date && {
        attendance_date: formData.attendance_date,
      }),
    };
    // Call the API request function only if required date fields are present
    handleCreateAttendance(apiRequestData);
    // Reset the form
    setFormData({
      attendance_work: workDetails.work_id,
      attendance_emp: current_user_id,
      attendance_emp_team: current_user_team_id,
      attendance_is_accompanied_by: '',
      attendance_is_anchorage: false,
      attendance_is_dubaidrydock: false,
      attendance_is_overseas: false,
      attendance_remarks: '',
      attendance_is_completed: false,
      attendance_is_not_possible: false,
      attendance_is_sr_available: false,
      attendance_sr_numbers: '',
      attendance_sr_upload_location: '',
      attendance_is_do_available: false,
      attendance_do_numbers: '',
      attendance_do_upload_location: '',
      attendance_date: '',
      attendance_start_date: '',
      attendance_end_date: '',
    });
  };

  const handleDateChange = (e, dateField) => {
    const selectedDate = new Date(e.target.value);
    const currentDate = new Date();

    if (!allowedPreviousDates) {
      alert('Fetching allowed previous dates. Please wait.');
      return;
    }
    // Calculate the difference in milliseconds
    const timeDifference = currentDate - selectedDate;
    const daysDifference = timeDifference / (1000 * 3600 * 24);

    if (daysDifference < 0 || daysDifference > allowedPreviousDates) {
      alert(`You can only select the current date and the ${allowedPreviousDates - 1} previous dates.`);
      // Optionally reset the date field
      setFormData((prevData) => ({
        ...prevData,
        [dateField]: '',
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [dateField]: e.target.value,
      }));
    }
  };

  return (
    <div className='box'>
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="mb-3">
          <label className="form-check-label">
            <input
              type="checkbox"
              name="attendance_is_anchorage"
              checked={formData.attendance_is_anchorage}
              onChange={handleInputChange}
              className="form-check-input"
            />
            Anchorage
          </label>
        </div>

        <div className="mb-3">
          <label className="form-check-label">
            <input
              type="checkbox"
              name="attendance_is_dubaidrydock"
              checked={formData.attendance_is_dubaidrydock}
              onChange={handleInputChange}
              className="form-check-input"
            />
            Dubai Dry Dock
          </label>
        </div>

        <div className="mb-3">
          <label className="form-check-label">
            <input
              type="checkbox"
              name="attendance_is_overseas"
              checked={formData.attendance_is_overseas}
              onChange={handleInputChange}
              className="form-check-input"
            />
            Overseas
          </label>
        </div>

        <div className="mb-3">
          <label className="form-check-label">
            <input
              type="checkbox"
              name="attendance_is_not_possible"
              checked={formData.attendance_is_not_possible}
              onChange={handleInputChange}
              className="form-check-input"
            />
            Not possible
          </label>
        </div>

        <div className="mb-3">
          <label className="form-check-label">
            <input
              type="checkbox"
              name="attendance_is_completed"
              checked={formData.attendance_is_completed}
              onChange={handleInputChange}
              className="form-check-input"
            />
            Completed
          </label>
        </div>

        {/* ... Add other checkboxes for attendance types as needed ... */}

        <div className="mb-3">
          <label className="form-check-label">
            <input
              type="checkbox"
              name="attendance_is_do_available"
              checked={formData.attendance_is_do_available}
              onChange={handleInputChange}
              className="form-check-input"
            />
            DO Available
          </label>
        </div>
        
        {formData.attendance_is_do_available && (
          <>
            <div className="mb-3">
              <label htmlFor="attendance_do_numbers" className="form-label">
                DO Numbers
              </label>
              <input
                type="text"
                id="attendance_do_numbers"
                name="attendance_do_numbers"
                value={formData.attendance_do_numbers}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>

            <div className="mb-3">
              <label htmlFor="attendance_do_upload_location" className="form-label">
                DO Upload Location
              </label>
              <input
                type="file"
                id="attendance_do_upload_location"
                name="attendance_do_upload_location"
                onChange={(e) => handleFileChange(e, 'attendance_do_upload_location')}
                className="form-control"
              />
            </div>
          </>
        )}

        <div className="mb-3">
          <label className="form-check-label">
            <input
              type="checkbox"
              name="attendance_is_sr_available"
              checked={formData.attendance_is_sr_available}
              onChange={handleInputChange}
              className="form-check-input"
            />
            SR Available
          </label>
        </div>

        {formData.attendance_is_sr_available && (
          <>
            <div className="mb-3">
              <label htmlFor="attendance_sr_numbers" className="form-label">
                SR Numbers
              </label>
              <input
                type="text"
                id="attendance_sr_numbers"
                name="attendance_sr_numbers"
                value={formData.attendance_sr_numbers}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>

            <div className="mb-3">
              <label htmlFor="attendance_sr_upload_location" className="form-label">
                SR Upload Location
              </label>
              <input
                type="file"
                id="attendance_sr_upload_location"
                name="attendance_sr_upload_location"
                onChange={(e) => handleFileChange(e, 'attendance_sr_upload_location')}
                className="form-control"
              />
            </div>
          </>
        )}

        <div className="mb-3">
          {formData.attendance_is_anchorage ? (
            <>
              <div className="mb-3">
                <label htmlFor="attendance_start_date" className="form-label">
                  Start Date
                </label>
                <input
                  type="date"
                  id="attendance_start_date"
                  name="attendance_start_date"
                  value={formData.attendance_start_date}
                  onChange={(e) => handleDateChange(e, 'attendance_start_date')}
                  className="form-control"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="attendance_end_date" className="form-label">
                  End Date
                </label>
                <input
                  type="date"
                  id="attendance_end_date"
                  name="attendance_end_date"
                  value={formData.attendance_end_date}
                  onChange={(e) => handleDateChange(e, 'attendance_end_date')}
                  className="form-control"
                />
              </div>
            </>
          ) : (
            <div className="mb-3">
              <label htmlFor="attendance_date" className="form-label">
                Date
              </label>
              <input
                type="date"
                id="attendance_date"
                name="attendance_date"
                value={formData.attendance_date}
                onChange={(e) => handleDateChange(e, 'attendance_date')}
                className="form-control"
              />
            </div>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="attendance_is_accompanied_by" className="form-label">
            Accompanied By
          </label>
          <input
            type="text"
            id="attendance_is_accompanied_by"
            name="attendance_is_accompanied_by"
            value={formData.attendance_is_accompanied_by}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>

        <div className="mb-3">
          <label htmlFor="attendance_remarks" className="form-label">
            Remarks
          </label>
          <input
            type="text"
            id="attendance_remarks"
            name="attendance_remarks"
            value={formData.attendance_remarks}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>

        <button type="submit" className="btn btn-primary mt-3">
          Submit Attendance
        </button>
      </form>
    </div>
  );
};

export default AttendanceForm;