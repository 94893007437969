import React, { useState, useEffect } from "react";
import ApproveFormManager from "./ApproveFormManager";
import {
  getAllTimesheetsByFilter,
  generateReportApi,
  updateTimesheetProcessData,
} from "./helper/coreapicalls";
import Pagination from "./Pagination"; // Import the Pagination component
import DetailedUserView from "./DetailedUserView";
import TimesheetSearch from "./TimesheetSearch";

import { toast, ToastContainer } from "react-toastify"; // Import toast
import "react-toastify/dist/ReactToastify.css"; // Import CSS

const TeamLeadControl = () => {
  const [selectedTimesheet, setSelectedTimesheet] = useState(null);
  const [selectedTimesheetView, setSelectedTimesheetView] = useState(null);
  const [timesheetsData, setTimesheetsData] = useState([]);
  // const [alltimesheetsData, setAllTimesheetsData] = useState([]);
  
  const [alltimesheetsData, setAllTimesheetsData] = useState({ timesheetsData: [], count: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});

  const handleSearch = async (searchFilters) => {
    console.log("searchFilters", searchFilters);
    setFilters(searchFilters);

    setCurrentPage(1);
    await fetchTimesheets(1, searchFilters);
  };

  const handleReportGen = async (searchFilters) => {
    setFilters(searchFilters);
    setCurrentPage(1);
    await generateReport(1, searchFilters);
  };

  useEffect(() => {
    fetchTimesheets(currentPage, filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchTimesheets = async (page = 1, searchFilters = filters) => {
    console.log("searchFilters in search", searchFilters.manager);
    try {
      setLoading(true);
      let managerFilter = null;
      let tlFilter = null;
      if (searchFilters.teamlead === "true") {
        tlFilter = true;
      } else if (searchFilters.teamlead === "false") {
        tlFilter = false;
      } else if (searchFilters.teamlead === "all") {
        tlFilter = null;
      }

      if (searchFilters.manager === "true") {
        managerFilter = true;
      } else if (searchFilters.manager === "false") {
        managerFilter = false;
      } else if (searchFilters.manager === "all") {
        managerFilter = null;
      }



      const result = await getAllTimesheetsByFilter(
        tlFilter,
        managerFilter,
        searchFilters.sr === true ? searchFilters.sr : null ,
        searchFilters.teamId && searchFilters.teamId.team_id
          ? searchFilters.teamId.team_id
          : null,
        searchFilters.branchId && searchFilters.branchId.branch_id
          ? searchFilters.branchId.branch_id
          : null,
        page,
        searchFilters.msid,
        searchFilters.eng_name,
        searchFilters.vessel_name,
        searchFilters.startdate,
        searchFilters.enddate,
        searchFilters.Job_No,
        searchFilters.OT === true ? searchFilters.OT : null,
      );
      // console.log(result.timesheetsData)
      setAllTimesheetsData(result);
      setTimesheetsData(result.timesheetsData);
      setTotalPages(Math.ceil(result.count / 100));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching timesheet data:", error);
      setLoading(false);
    }
  };

  const generateReport = async (page = 1, searchFilters = filters) => {
    try {
      setLoading(true);

      let managerFilter = null;
      let tlFilter = null;
      if (searchFilters.teamlead === "true") {
        tlFilter = true;
      } else if (searchFilters.teamlead === "false") {
        tlFilter = false;
      } else if (searchFilters.teamlead === "all") {
        tlFilter = null;
      }

      if (searchFilters.manager === "true") {
        managerFilter = true;
      } else if (searchFilters.manager === "false") {
        managerFilter = false;
      } else if (searchFilters.manager === "all") {
        managerFilter = null;
      }
      
      const result = await generateReportApi(
        tlFilter,
        managerFilter,
        searchFilters.sr === true ? searchFilters.sr : null,
        searchFilters.teamId && searchFilters.teamId.team_id
          ? searchFilters.teamId.team_id
          : null,
        searchFilters.branchId && searchFilters.branchId.branch_id
          ? searchFilters.branchId.branch_id
          : null,
        page,
        searchFilters.msid,
        searchFilters.eng_name,
        searchFilters.vessel_name,
        searchFilters.startdate,
        searchFilters.enddate,
        searchFilters.Job_No,
        searchFilters.OT === true ? searchFilters.OT : null,
      );
      downloadFileFromResult(result);
      // setAllTimesheetsData(result.timesheetsData);
      // setTotalPages(Math.ceil(result.count / 100));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching timesheet data:", error);
      setLoading(false);
    }
  };

  const downloadFileFromResult = ({ blob, headers }) => {
    // Create a new Blob object using the response data (the Excel file)
    const fileBlob = new Blob([blob], { type: headers.get("content-type") });

    // Create a temporary anchor element to trigger the download
    const url = window.URL.createObjectURL(fileBlob);
    const link = document.createElement("a");
    link.href = url;

    // Get the filename from the Content-Disposition header if available
    const contentDisposition = headers.get("content-disposition");
    let fileName = "downloaded_file.xlsx"; // Default filename
    if (contentDisposition && contentDisposition.includes("filename=")) {
      fileName = contentDisposition
        .split("filename=")[1]
        .split(";")[0]
        .replace(/"/g, ""); // Extract the filename
    }

    // Set the file name for the downloaded file
    link.setAttribute("download", fileName);

    // Append the link to the document body and click it to start the download
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const normalUserfilterConfig = [
    { type: "text", name: "msid", label: "MSID" },
    { type: "text", name: "eng_name", label: "Eng_Name" },
    { type: "text", name: "vessel_name", label: "Vessel_Name" },
    { type: "text", name: "Job_No", label: "Job_No" },
    { type: "date", name: "startdate", label: "Start" },
    { type: "date", name: "enddate", label: "End" },
    { type: "select", name: "Team", label: "Team" },
    { type: "select", name: "Branch", label: "Branch" },
    { type: "checkbox", name: "OT", label: "Overtime" },
    // { type: "checkbox", name: "teamlead", label: "Teamlead" },
    {
      type: "customSelect",
      name: "teamlead",
      label: "Teamlead",
      options: [
        { value: "all", label: "All" },
        { value: "true", label: "Approved" },
        { value: "false", label: "Not Approved" },
       
      ],
    },
    // { type: "checkbox", name: "manager", label: "Manager" },
    {
      type: "customSelect",
      name: "manager",
      label: "Manager",
      options: [
        { value: "all", label: "All" },
        { value: "true", label: "Approved" },
        { value: "false", label: "Not Approved" },
       
      ],
    },
    { type: "checkbox", name: "sr", label: "SR" },
  ];

  const loadingMessage = () => {
    return (
      loading && (
        <div className="overlay">
          <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="spinner-border text-danger" role="status"></div>
          </div>
        </div>
      )
    );
  };

  const handleViewClick = (timesheet) => {
    setSelectedTimesheetView(timesheet);
    // console.log("selected timesheet", timesheet);
  };
  const handleEditClick = (timesheet) => {
    setSelectedTimesheet(timesheet);
  };

  function decimalToTime(decimalHours) {
    // Split the decimal into hours and minutes
    var hours = Math.floor(decimalHours);
    var minutes = Math.round((decimalHours - hours) * 60);

    // Format the time
    var formattedTime = hours + ":" + (minutes < 10 ? "0" : "") + minutes;

    return formattedTime;
  }
  const handleSaveEdit = (editedTimesheet) => {
    setTimesheetsData((prevData) =>
      prevData.map((timesheet) =>
        timesheet.timesheet_id === editedTimesheet.timesheet_id
          ? editedTimesheet
          : timesheet
      )
    );
    setSelectedTimesheet(null);
  };
  const handleCloseEdit = () => {
    setSelectedTimesheet(null);
  };
  const handleCloseView = () => {
    setSelectedTimesheetView(null);
  };
  const handleNextPage = async (newPage) => {
    setCurrentPage(newPage);
    await fetchTimesheets(newPage, filters);
  };



  const handleProcessClick = async (timesheet) => {
    try {
      setLoading(true);

      // Optimistic Update (Optional):
      setTimesheetsData((prevData) =>
        prevData.map((item) =>
          item.timesheet_id === timesheet.timesheet_id
            ? {
                ...timesheet,
                timesheet_dept_manager_approve:
                  !timesheet.timesheet_dept_manager_approve,
              }
            : item
        )
      );

      const updatedTimesheet = { ...timesheet };
      updatedTimesheet.timesheet_dept_manager_approve =
        !timesheet.timesheet_dept_manager_approve;
      const jsonData = {
        timesheet_dept_manager_approve:
          updatedTimesheet.timesheet_dept_manager_approve,
      };

      await updateTimesheetProcessData(timesheet.timesheet_id, jsonData);

      setLoading(false);

      // Show success toast notification
      toast.success("Timesheet updated successfully!", {
        position: "top-right", // Adjust position as needed
        autoClose: 3000,
        pauseOnFocusLoss: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error("Error updating timesheet:", error);
      setLoading(false);

      // Revert Optimistic Update if API call fails (Optional):
      setTimesheetsData((prevData) =>
        prevData.map((item) =>
          item.timesheet_id === timesheet.timesheet_id
            ? {
                ...timesheet,
                timesheet_dept_manager_approve:
                  timesheet.timesheet_dept_manager_approve,
              }
            : item
        )
      );

      toast.error("Error updating timesheet. Please try again.", {
        // Show error toast
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <div className="row">
      {loadingMessage()}
      <ToastContainer />


      <div className="pb-2 d-flex ">
        <div className="mt-2">
          <div>
            <TimesheetSearch
              initialConfig={normalUserfilterConfig}
              onSearch={handleSearch}
              onReportGen={handleReportGen}
            />
          </div>
        </div>
      </div>
      <div
          className="card border-left-primary shadow-lg m-2"
          style={{ width: 200, backgroundColor: "Indigo" }}
        >
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col mr-2">
                <div
                  className=" font-weight-bold text-white"
                  style={{ fontSize: "13px" }}
                >
                  Result Count:  {alltimesheetsData.count}
                </div>
              </div>
            </div>
          </div>
        </div>
      <div
        className="table-container card border-left-primary shadow m-2"
        style={{ maxHeight: "500px", overflowY: "auto", overflowX: "scroll" }}
      >
        <table className="table table-striped">
          <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
            <tr
              style={{
                maxHeight: 30,
                fontSize: 12,
                alignContent: "center",
                textAlign: "center",
              }}
            >
              <th style={{ maxWidth: 30 }}>View</th>
              <th style={{ maxWidth: 30 }}>Edit</th>
              <th style={{ maxWidth: 85 }}>
                Date
                <br />
                y-m-d
              </th>
              <th style={{ maxWidth: 85 }}>
                Eng
                <br />
                Name
              </th>
              <th style={{ maxWidth: 65 }}>
                Start
                <br />
                Time
              </th>
              <th style={{ maxWidth: 65 }}>
                Sign
                <br />
                In
              </th>
              <th style={{ maxWidth: 65 }}>
                Sign
                <br />
                Out
              </th>
              <th style={{ maxWidth: 65 }}>Return</th>
              <th style={{ maxWidth: 58 }}>
                Normal
                <br />
                Hrs
              </th>
              <th style={{ maxWidth: 45 }}>
                Total
                <br />
                Hrs
              </th>
              <th style={{ maxWidth: 52 }}>
                OT
                <br />
                Hrs
              </th>
              <th style={{ maxWidth: 45 }}>
                Exp.
                <br />
                OT
              </th>
              <th style={{ maxWidth: 59 }}>
                OT
                <br />
                Amt
              </th>
              <th style={{ maxWidth: 72 }}>Alw.Amt</th>
              <th style={{ maxWidth: 40 }}>Total</th>
              <th style={{ maxWidth: 50 }}>H.day</th>
              <th style={{ maxWidth: 50 }}>Anch</th>
              <th style={{ maxWidth: 53 }}>OS</th>
              <th style={{ maxWidth: 48 }}>DryD</th>
              <th style={{ maxWidth: 50 }}>
                Team
                <br />
                Lead
              </th>
              <th style={{ maxWidth: 45 }}>Dept Mgr</th>
              <th style={{ maxWidth: 56 }}>
                Final
                <br />
                Status
              </th>
              <th style={{ maxWidth: 65 }}>
                Process
                <br />
                Status
              </th>
              <th style={{ maxWidth: 100 }}>Comments</th>
            </tr>
          </thead>
          <tbody>
            {timesheetsData?.map((timesheet, index) => (
              <tr
                key={timesheet.timesheet_id}
                style={{ fontSize: 13 }}
                className={
                  timesheet.timesheet_dept_manager_approve
                    ? "table-success"
                    : ""
                }
              >
                <td>
                  <button
                    type="button"
                    className="btn fa fa-eye"
                    style={{ color: "blue", maxWidth: 30 }}
                    onClick={() => handleViewClick(timesheet)}
                  ></button>

                  {timesheet.timesheet_attendance_id
                    .attendance_sr_upload_location && (
                    <a
                      href={
                        timesheet.timesheet_attendance_id
                          .attendance_sr_upload_location
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn"
                      style={{ color: "blue", maxWidth: 30 }}
                    >
                      <i className="fa-solid fa-file"></i>
                    </a>
                  )}
                </td>
                <td>
                  <button
                    type="button"
                    className="btn fa fa-edit"
                    style={{ color: "blue", maxWidth: 30 }}
                    onClick={() => handleEditClick(timesheet)}
                  ></button>
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 85,
                  }}
                >
                  {timesheet.timesheet_date}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 85,
                  }}
                >
                  {timesheet.emp_name}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 80,
                  }}
                >
                  {timesheet.timesheet_time_start}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 67,
                  }}
                >
                  {timesheet.timesheet_time_signin}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 67,
                  }}
                >
                  {timesheet.timesheet_time_signout}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 67,
                  }}
                >
                  {timesheet.timesheet_time_return}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 51,
                  }}
                >
                  {decimalToTime(timesheet.timesheet_normal_hours)}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 55,
                  }}
                >
                  {decimalToTime(timesheet.timesheet_total_hours)}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 52,
                  }}
                >
                  {decimalToTime(timesheet.timesheet_over_time_hours)}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 58,
                  }}
                >
                  {timesheet.timesheet_expected_amount}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 58,
                  }}
                >
                  {timesheet.timesheet_overtime_amount}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 64,
                  }}
                >
                  {timesheet.timesheet_allowance_amount}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    fontWeight: "bold",
                    maxWidth: 58,
                  }}
                >
                  {timesheet.timesheet_Total_amount}
                </td>
                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 46,
                  }}
                >
                  {timesheet.timesheet_is_holiday ? (
                    <span
                      className="fa fa-check badge text-white bg-success m-2"
                      aria-hidden="true"
                    >
                      &nbsp;
                    </span>
                  ) : (
                    <span
                      className="fa fa-times badge text-white bg-secondary m-2"
                      aria-hidden="true"
                    >
                      &nbsp;
                    </span>
                  )}
                </td>

                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 40,
                  }}
                >
                  {timesheet.timesheet_attendance_id.attendance_is_anchorage ? (
                    <span
                      className="fa fa-check badge text-white bg-success m-2"
                      aria-hidden="true"
                    >
                      &nbsp;
                    </span>
                  ) : (
                    <span
                      className="fa fa-times badge text-white bg-secondary m-2"
                      aria-hidden="true"
                    >
                      &nbsp;
                    </span>
                  )}
                </td>

                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 53,
                  }}
                >
                  {timesheet.timesheet_attendance_id.attendance_is_overseas ? (
                    <span
                      className="fa fa-check badge text-white bg-success m-2"
                      aria-hidden="true"
                    >
                      &nbsp;
                    </span>
                  ) : (
                    <span
                      className="fa fa-times badge text-white bg-secondary m-2"
                      aria-hidden="true"
                    >
                      &nbsp;
                    </span>
                  )}
                </td>

                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 40,
                  }}
                >
                  {timesheet.timesheet_attendance_id
                    .attendance_is_dubaidrydock ? (
                    <span
                      className="fa fa-check badge text-white bg-success m-2"
                      aria-hidden="true"
                    >
                      &nbsp;
                    </span>
                  ) : (
                    <span
                      className="fa fa-times badge text-white bg-secondary m-2"
                      aria-hidden="true"
                    >
                      &nbsp;
                    </span>
                  )}
                </td>

                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 40,
                  }}
                >
                  {timesheet.timesheet_teamlead_approve ? (
                    <span className="fa-solid fa-thumbs-up badge bg-success m-2">
                      &nbsp;
                    </span>
                  ) : (
                    <span className="badge bg-warning text-dark m-2 fa-solid fa-thumbs-down">
                      &nbsp;
                    </span>
                  )}
                </td>

                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                  }}
                >
                  {timesheet.timesheet_dept_manager_approve ? (
                    <button
                    disabled={
                      !timesheet.timesheet_total_hours &&
                      timesheet.timesheet_total_hours !== 0
                     } 
                      className="btn btn-success m-2"
                      onClick={() => handleProcessClick(timesheet)}
                      style={{ fontSize: "10px", padding: "1px 6px" }} // Bigger button
                    >
                      <i className="fa-solid fa-thumbs-up"></i>
                    </button>
                  ) : (
                    <button
                         disabled={
                      !timesheet.timesheet_total_hours &&
                      timesheet.timesheet_total_hours !== 0
                     } 
                      className="btn btn-warning text-dark m-2"
                      onClick={() => handleProcessClick(timesheet)}
                      style={{ fontSize: "10px", padding: "1px 6px" }} // Smaller button
                    >
                      <i className="fa-solid fa-thumbs-down"></i>
                    </button>
                  )}
                </td>

                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 55,
                  }}
                >
                  {!timesheet.timesheet_dept_manager_approve ? (
                    <button
                      type="button"
                      className="btn btn-outline-danger "
                      style={{ maxWidth: 65, fontSize: 11, padding: 2 }}
                    >
                      Waiting
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-outline-success "
                      style={{ maxWidth: 65, fontSize: 11, padding: 2 }}
                    >
                      Completed
                    </button>
                  )}
                </td>

                <td
                  style={{
                    alignContent: "center",
                    textAlign: "center",
                    maxWidth: 55,
                  }}
                >
                  {!timesheet.timesheet_final_process_status ? (
                    <button
                      type="button"
                      className="btn btn-outline-danger m-2"
                      style={{ fontSize: 11, padding: 2 }}
                    >
                      Waiting
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-outline-success m-2"
                      style={{ fontSize: 11, padding: 2 }}
                    >
                      Completed
                    </button>
                  )}
                </td>

                <td>{timesheet.timesheet_comments}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onNextPage={() => handleNextPage(currentPage + 1)}
        onPrevPage={() => handleNextPage(currentPage - 1)}
      />
      {selectedTimesheet && (
        <ApproveFormManager
          timesheet={selectedTimesheet}
          onUpdate={handleSaveEdit}
          onClosePopup={handleCloseEdit}
        />
      )}
      {selectedTimesheetView && (
        <DetailedUserView
          timesheet={selectedTimesheetView}
          onClosePopup={handleCloseView}
        />
      )}
    </div>
  );
};

export default TeamLeadControl;
