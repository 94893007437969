import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const WorkDetails = ({ workDetails }) => {
  const filteredDetails = Object.fromEntries(
    Object.entries(workDetails).filter(
      ([key]) =>
        !['created_at', 'updated_at', 'work_job_pic_name', 'work_id', 'work_updated_emp','work_added_emp','work_dept_id','work_active','work_branch_id', 'work_country_id'].includes(key)
    ).map(([key, value]) => {
      if (key === 'branch_name') {
        return ['database', value];
      }
      return [key, value];
    })
  );

  return (
    <div className="container d-flex justify-content-center">
      <table className="table table-bordered table-striped" style={{ maxWidth: 1000 }}>
        <thead>
          <tr>
            <th>Field</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(filteredDetails).map(([key, value]) => {
            const isHighlighted = key === 'database' || key === 'work_job_number'; // Highlight these keys
            return (
              <tr key={key} className={isHighlighted ? 'table-warning' : ''}> {/* Add Bootstrap class */}
                <td>{key.replace('work_', '').replace('_', ' ')}</td>
                <td>{value}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default WorkDetails;