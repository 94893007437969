

import React, { useEffect, useState } from 'react';
import { isAuthenticated } from '../auth/helper';
import { getAllUsers, getCountries, getBranches, getDepartments, getTeams } from '../core/helper/coreapicalls';
import AllUsersList1 from '../core/AllUsersList1';
import SearchUser from '../core/SearchUser';
import Menu from '../core/Menu';
import WhatsAppButton from '../core/WhatsAppButton';
import Footer from '../core/Footer';
import ManagerApprovalControl from '../core/ManagerApprovalControl';
import DateControl from '../core/DateControl';


const ManagerDashboard = () => {
    const [userData, setUserData] = useState(null);
    const [userList, setUserList] = useState([]);
    const [filteredUserList, setFilteredUserList] = useState([]);
    const [countryData, setCountryData] = useState([]);
    const [branchData, setBranchData] = useState([]);
    const [departmentData, setDepartmentData] = useState([]);
    const [teamData, setTeamData] = useState([]);
    const [allUsersList, setAllUsersListData] = useState(false);
    const [allTimesheetsList, setallTimesheetsList] = useState(true);
    const [searchUser, setsearchUser] = useState(false);
    const [dateAccess, setdateAccess] = useState(false);
    const handleTimesheetButtonClick = () => {
        setAllUsersListData(false);
        setsearchUser(false);
        setdateAccess(false);
        setallTimesheetsList(true);
    };
    const handleDateControlButtonClick = () => {
        setAllUsersListData(false);
        setsearchUser(false);
        setallTimesheetsList(false);
        setdateAccess(true);
    };
    const handleUserButtonClick = () => {
        setsearchUser(true);
        setallTimesheetsList(false);
        setdateAccess(false);
        setAllUsersListData(true);
    };
    useEffect(() => {
        
        const fetchData = async () => {
            const user = await isAuthenticated();
            setUserData(user);
            const allUsersFromAPI = await getAllUsers();
            // If you need to convert it back to JSON string
            const modifiedUsersJSON = allUsersFromAPI.filter(user => user.id !== 1);
            setUserList(modifiedUsersJSON);
            const countries = await getCountries();
            const branches = await getBranches();
            const departments = await getDepartments();
            const teams = await getTeams();
            setCountryData(countries);
            setBranchData(branches);
            setDepartmentData(departments);
            setTeamData(teams);
        };
        fetchData();
    }, []); // Empty dependency array ensures useEffect runs once on mount
    const handleSearch = (query) => {
        const filteredUsers = userList.filter((user) =>
            user.emp_name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredUserList(filteredUsers);
    };
    return (
        <div className="dashboard-wrapper ">
            <div className="container-grow">
                <Menu></Menu>
                <WhatsAppButton></WhatsAppButton>
                {userData && (
                    <div className="m-3 ">
                        <div className="d-flex">
                            <div className='pr-5'>Welcome, &nbsp;</div>
                            <div className="pl-5">{userData.emp_name}</div>
                        </div>
                        <div className='pb-2 d-flex '>
                            <div className='mt-2'>
                                <button type="button" className="btn btn-primary m-2" style={{ height: '40px' }} onClick={handleUserButtonClick}>
                                    Users
                                </button></div>

                            <div className='mt-2'>
                                <button type="button" className="btn btn-primary m-2" style={{ height: '40px' }} onClick={handleTimesheetButtonClick}>
                                    Time Sheets
                                </button>
                            </div>
                            <div className='mt-2'>
                                <button type="button" className="btn btn-primary m-2" style={{ height: '40px' }} onClick={handleDateControlButtonClick}>
                                    Date Control
                                </button>
                            </div>
                            <div className='mt-2'>
                                <div className=" m-2" style={{ height: '40px' }}>
                                {searchUser && <SearchUser onSearch={handleSearch} />}
                                </div>
                            </div>
                        {/* <div >    {searchUser && <SearchUser onSearch={handleSearch} />}</div> */}
                        </div>
                        <div>{
                            allUsersList && (
                                <  AllUsersList1
                                    userList={filteredUserList.length > 0 ? filteredUserList : userList}
                                    countryData={countryData}
                                    branchData={branchData}
                                    departmentData={departmentData}
                                    teamData={teamData}
                                    updateUserList={setUserList} // Ensure updateUserList is defined in your component
                                />
                            )}
                            {allTimesheetsList && (
                                <div>
                                    <ManagerApprovalControl />
                                </div>
                            )}
                            {dateAccess&& userData.emp_is_date_controller && userData.emp_is_date_controller ===true && (<div><DateControl></DateControl></div>)}

                        </div>
                    </div>
                )}
            </div>
            <Footer></Footer>
        </div>
    );
};
export default ManagerDashboard;
